<script>
	import textContent from '../../data/textContent.json';
	const introText = textContent.sections.find((d) => d.id === 'intro');
</script>

<div class="intro-container">
	<h2>{@html introText.subtitle}</h2>
</div>

<style lang="scss">
	* {
		// border: solid 1px red;
	}

	.intro-container {
		height: 100vh;
		margin: 0 10vw;
		padding-top: 20vh;

		:global(h2) {
			font-family: 'Manrope', sans-serif;
		}

		:global(b) {
			font-weight: 800;
		}

		:global(.normal) {
			color: var(--red);
		}

		:global(.unusual) {
			color: var(--yellow);
		}

		:global(.new-normal) {
			color: var(--blue);
		}
	}

	h2 {
		width: 80vw;
		font-size: 48px;
		font-weight: 600;
		line-height: 1.3em;
	}

	p {
		width: 60vw;
		max-width: 720px;
		font-size: 18px;
		line-height: 30px;
	}

	@media screen and (max-width: 1024px) {
		h2 {
			font-size: 38px;
		}
	}

	@media screen and (max-width: 600px) {
		h2 {
			font-size: 32px;
		}
	}
</style>
