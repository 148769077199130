<script>
	import { createEventDispatcher } from 'svelte';
	import { fade } from 'svelte/transition';
	import mq from '../stores/mq.js';

	export let currentSlideIdx;

	$: isMobile = $mq.sm;

	let showArrow = false;
	let icon;
	let text;
	let dirClass;
	let clickMsg;
	$: if (currentSlideIdx <= 2) {
		// Down Arrow on Into and Shapes slides
		showArrow = true;
		icon = './assets/Icon-DownArrow.svg';
		text = 'Explore';
		dirClass = 'text-first';
		clickMsg = 'nextClick';
	} else if (currentSlideIdx === 4) {
		// Up Arrow on Methods Slide
		showArrow = true;
		icon = './assets/Icon-UpArrow.svg';
		text = 'Back to Top';
		dirClass = 'arrow-first';
		clickMsg = 'homeClick';
	} else {
		showArrow = false;
	}

	const dispatch = createEventDispatcher();
	function emitEvent(msg) {
		dispatch(msg);
	}
</script>

{#if !isMobile && showArrow}
	<div class="nav-arrow-container">
		<div
			in:fade={{ delay: 500 }}
			on:click={() => emitEvent(clickMsg)}
			class={`nav-arrow ${dirClass}`}
		>
			<div>{text}</div>
			<img alt='Navigation arrow icon' src={icon} />
		</div>
	</div>
{/if}

<style lang="scss">
	.nav-arrow-container {
		position: fixed;
		z-index: 9999;
		bottom: 0;

		display: flex;
		justify-content: center;

		width: 100%;
		height: 75px;

		transition: opacity 0.5s, transform 0.5s;

		opacity: 1;

		font-size: 16px;
		font-weight: 700;
	}

	.nav-arrow {
		display: flex;
		align-items: center;
		flex-direction: column;

		cursor: pointer;

		&.arrow-first {
			flex-direction: column-reverse;
			justify-content: flex-end;
		}
	}

	img {
		width: 28px;
		margin: 10px 0;
	}

	@media (hover: hover) {
		.nav-arrow-container:hover {
			// transform: scale(1.25);

			opacity: 0.5;
		}
	}
</style>
