<script>
	import * as d3 from 'd3';

	import { LayerCake, Svg, Html } from 'layercake';
	import { onMount } from 'svelte';

	import CoverChart from './Cover.chart.svelte';
	import CoverAnnotation from './Cover.annotation.svelte';

	import textContent from '../../data/textContent.json';
	const coverText = textContent.sections.find((d) => d.id === 'cover');

	onMount(() => {});
</script>

<div class="cover-container">
	<!-- CHART LAYER -->
	<div class="chart-container">
		<LayerCake
			xScale={d3.scaleTime()}
			xDomain={[new Date(2019, 0, 15), new Date(2021, 11, 15)]}
			yDomain={[0, 100]}
			padding={{ top: 50, bottom: 0 }}
		>
			<Svg>
				<CoverChart />
			</Svg>

			<Html>
				<CoverAnnotation />
			</Html>
		</LayerCake>
	</div>

	<!-- TEXT OVERLAY LAYER -->
	<div class="text-container">
		<div class="title-container">
			<h1>{coverText.title}</h1>
			<h2>{@html coverText.subtitle}</h2>
		</div>
		<div class="attrib-container">
			<!-- <div>
				"The New Normal" is a collaboration between <a
					href="https://www.schemadesign.com/"
					target="_blank">Schema Design</a
				>
				and
				<a
					href="https://trends.google.com/trends/?geo=US"
					target="_blank">Google News Initiative</a
				>, and
				<a href="https://www.axios.com/" target="_blank">Axios</a>. Art
				direction
				<span style="white-space: nowrap;"
					>by
					<a href="http://albertocairo.com/" target="_blank"
						>Alberto Cairo</a
					>.</span
				>
			</div> -->

			<div class="logo-container">
				<a href="https://www.schemadesign.com" target="_blank" rel="noopener"
					><img
						src="./assets/Logo-Schema.svg"
						class="logo logo-schema"
						alt="Schema"
					/></a
				>
				<a href="https://trends.google.com/trends" target="_blank" rel="noopener"
					><img
						src="./assets/Logo-GoogleTrends.svg"
						class="logo logo-google"
						alt="Google News Initiative"
					/></a
				>
				<a href="https://www.axios.com" target="_blank" rel="noopener"
					><img
						src="./assets/Logo-Axios.svg"
						class="logo logo-axios"
						alt="Axios"
					/></a
				>
			</div>
		</div>
	</div>
</div>

<style lang="scss">
	// * {
	// 	border: solid 1px red;
	// }

	.cover-container {
		position: relative;
		height: 100vh;
		margin: 0 10vw;
	}

	.chart-container {
		transform: translateY(5px);
		width: 100%;
		height: 100%;
		max-height: 85vh;
	}

	.text-container {
		position: absolute;
		top: 0;
		width: 100%;
		margin: calc(10vh + 48px) 0;
		display: flex;
		justify-content: space-between;
	}

	.title-container {
		width: 50%;

		h1 {
			margin: 0;
			font-size: 100px;
			line-height: 78%;
		}

		h2 {
			margin: 20px 0;
			width: 80%;
			font-size: 24px;
			font-weight: 400;
		}
	}

	.attrib-container {
		max-width: 300px;
		position: absolute;
		right: 0px;
	}

	.about-button {
		text-decoration: underline;
		padding: 10px 0;

		&:hover {
			cursor: pointer;
			opacity: 75%;
		}
	}

	a:hover {
		opacity: 75%;
	}

	.logo-container {
		width: 100%;
		min-width: 300px;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		.logo {
			margin-right: 20px;
			transform: scale(1);
		}

		.logo-schema {
			position: relative;

			width: 80px;
			height: auto;
		}

		.logo-google {
			position: relative;
			bottom: -5px;

			width: 125px;
			height: auto;
		}

		.logo-axios {
			width: 60px;
			height: auto;
			margin-right: 0px;
		}
	}

	@media screen and (max-width: 1024px) {
		.text-container {
			flex-direction: column;
			justify-content: flex-start;

			.title-container {
				width: 90%;
				padding-top: 65px;
			}

			.attrib-container {
				margin: 0;
				left: 0px;
			}

			.logo-container {
				min-width: 200px;
			}
		}
	}

	@media screen and (max-width: 600px) {
		.text-container {
			margin: calc(2vh + 48px) 0;

			.title-container {
				width: 100%;

				h1 {
					font-size: 64px;
					width: 95%;
				}

				h2 {
					font-size: 21px;
					width: 100%;
				}
			}

			.logo-container {
				padding-bottom: 15px;

				.logo {
					margin-right: 5px;
					transform: scale(.85);
				}
			}
		}
	}
</style>
