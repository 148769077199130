import * as d3 from 'd3';

export function processContent(sections, textSections) {
	//add text content to each content section
	sections = sections.map((section) => {
		const textSection = textSections.find((s) => s.id === section.id);
		if (textSection) {
			section.title = textSection.title;

			section.scenes = section.scenes.map((scene, sceneIndex) => {
				scene.steps = scene.steps.map((step, stepIndex) => {
					const textStep =
						textSection.scenes[sceneIndex].steps[stepIndex];
					if (textStep) {
						step.content = textStep.content;
					}
					return step;
				});
				return scene;
			});
		}

		return section;
	});

	// add index and intersection props to each section/scene/step
	let intersection = 0;
	return sections.map((section, sectionIndex) => {
		section.index = sectionIndex;

		section.intersectionId = `${section.index}`;
		section.intersectionIndex = intersection++;

		section.scenes = section.scenes.map((scene, sceneIndex) => {
			scene.index = sceneIndex;

			scene.intersectionId = `${section.index}/${scene.index}`;
			scene.intersectionIndex = intersection++;

			scene.steps = scene.steps.map((step, stepIndex) => {
				step.index = stepIndex;

				step.intersectionId = `${section.index}/${scene.index}/${step.index}`;
				step.intersectionIndex = intersection++;

				return step;
			});

			return scene;
		});

		return section;
	});
}

export function convertDataToTimeSeries(data) {
	return data.map((d, i) => {
		const year = 2019 + Math.floor(i / 12);
		const month = i % 12;

		return {
			date: new Date(year, month, 15),
			index: d,
		};
	});
}

export function getRandomTrends(trends, num) {
	if (!trends) return;

	return [...new Array(num)].map((_, i) => {
		const col = i % columns;
		const row = Math.floor(i / columns);

		let trend = trends[Math.round(Math.random() * 500)];

		return {
			...trend,

			start: Math.floor(Math.random() * 600),
			col,
			row,
			x: col * columnWidth - (columns * columnWidth) / 2,
			y: row * 100,
			data: trend.timeline.values,
		};
	});
}

export function getTrendsByCategory(trends) {
	if (!trends) return {};

	return Object.fromEntries(d3.group(trends, (d) => d.rootCategory));
}

export function getTrendsByCategoryAndModel(trends) {
	if (!trends) return {};

	return Object.fromEntries(
		d3.group(
			trends,
			(d) => d.rootCategory,
			(d) => d.profileCategory
		)
	);
}

export function getTrendByKeyword(trends, keyword) {
	if (!trends) return null;

	return trends.find((t) => keyword === t.keyword);
}

export function getTrendLineData(trend) {
	// return the trend data formatted for creating a line, i.e. {date: XXX, index: xxx}
	if (!trend) return null;
	let data = trend.timeline.values;
	if (!data) return;
	return data.map((d, i) => {
		const year = 2019 + Math.floor(i / 12);
		const month = i % 12;
		return {
			date: new Date(year, month, 15),
			index: d,
		};
	});
}

export function shuffle(array) {
	let currentIndex = array.length,
		randomIndex;

	// While there remain elements to shuffle...
	while (currentIndex != 0) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex],
			array[currentIndex],
		];
	}

	return array;
}
