<script>
	import * as d3 from 'd3';

	import { getContext, onMount, tick } from 'svelte';
	import mq from '../../stores/mq.js';
	import gsap from 'gsap';
	import { ScrollTrigger } from 'gsap/ScrollTrigger';
	gsap.registerPlugin(ScrollTrigger);

	export let isInView = false;
	export let chartIdx;
	export let keyword = '';
	export let color = '#000';
	export let trend = [];

	const { height, xScale, yScale } = getContext('LayerCake');

	$: isMobile = $mq.sm;

	$: pathFn = d3
		.line()
		.curve(d3.curveBasis)
		.x((d) => $xScale(d.date))
		.y((d) => $yScale(d.index));

	$: areaFn = d3
		.area()
		.curve(d3.curveBasis)
		.x((d) => $xScale(d.date))
		.y1((d) => $yScale(d.index))
		.y0($yScale(0));

	// --- Define the year labels -----------------------
	const formatDate = d3.timeFormat('%y');
	const startDate = new Date(2019, 0, 0);
	const endDate = new Date(2021, 11, 30);
	const yearLabels = [
		{ date: new Date(2019, 0, 1), anchor: 'start' },
		{ date: new Date(2020, 0, 1), anchor: 'middle' },
		{ date: new Date(2021, 0, 1), anchor: 'middle' },
		{ date: new Date(2022, 0, 1), anchor: 'end' },
	];

	// --- Define the keyword label -----------------------------
	let textNode;
	let textBox = { width: 0, height: 0 };
	$: {
		if (textNode) {
			textNode.textContent = keyword; // <-- set text content here so we can get width
			textNode = textNode;
			textBox = textNode.getBBox();
		}
	}

	let padding;
	padding = { left: 7, right: 7, top: 7, bottom: 7 };

	$: labelBox = {
		width: textBox.width + padding.left + padding.right,
		height: textBox.height + padding.top + padding.bottom,
	};

	// --- Define the animation -------------------------
	const setPathLength = async () => {
		await tick(); // <-- pathNode has to be updated with the new path first, so wait a tick
		if (pathNode) {
			pathLength = pathNode.getTotalLength();
		}
	};

	let pathNode;
	let pathLength = 0;
	let pathPoint = { x: 0, y: 0 };
	$: if (pathNode) {
		// convert tween progress to x,y along current path. This allows for path to draw at constant speed;
		if (pathLength == 0) {
			setPathLength();
		}
		pathPoint = pathNode.getPointAtLength(tween.val * pathLength); // rightmost point of the current path
	}

	let tl = gsap.timeline({ paused: true });
	let tween = { val: 0 };
	onMount(() => {
		tl.to(tween, {
			val: 1,
			duration: 3.5,
			ease: 'sine.inOut',
			onUpdate: () => {
				tween = tween; // <-- do this to tell svelte the object updated
			},
		});
	});

	$: if (isInView) {
		tl.play();
	} else {
		tl.pause(0);
	}
</script>

<!-- <defs>
	<clipPath id={`shape-trend-reveal-${chartIdx}`}> 
	<rect x={0} y={0} width={pathPoint.x} height={$height} fill="#F00" />
	</clipPath>
	<linearGradient
		id={`shape-trend-gradient-${chartIdx}`}
		x1="0%"
		y1="100%"
		x2="0%"
		y2="0%"
	>
		<stop offset="10%" stop-color={color} stop-opacity="0" />
		<stop offset="100%" stop-color={color} stop-opacity=".8" />
	</linearGradient> 
</defs> -->

<!-- AXES -->
<g class="axes">
	{#each ['H', 'L'] as axisLabel, i}
		<line
			x1={$xScale(startDate)}
			x2={$xScale(endDate)}
			y1={i * $height}
			y2={i * $height}
			stroke="#000"
			stroke-width={2}
		/>
		<text
			class="axis-label"
			text-anchor="end"
			dominant-baseline="middle"
			x={$xScale(startDate) - 3}
			y={i * $height}
			fill="#000">{axisLabel}</text
		>
	{/each}
	{#each yearLabels as yearLabel, i}
		<text
			class="axis-label"
			text-anchor={yearLabel.anchor}
			dominant-baseline="hanging"
			x={$xScale(yearLabel.date)}
			y={$height + 3}
			fill="#000">{`'${formatDate(yearLabel.date)}`}</text
		>
	{/each}
</g>

<!-- TREND -->
{#if trend}
	<g class="trend-line">
		<!-- <path
			bind:this={pathNode}
			class="trend-fill"
			d={areaFn(trend)}
			fill={`url(#shape-trend-gradient-${chartIdx})`}
			clip-path={`url(#shape-trend-reveal-${chartIdx})`}
		/> -->
		<path
			bind:this={pathNode}
			d={pathFn(trend)}
			stroke={color}
			stroke-width={6}
			stroke-linecap="round"
			stroke-dasharray={pathLength}
			stroke-dashoffset={pathLength - tween.val * pathLength}
			fill="none"
		/>
	</g>

	<g transform={`translate(0, ${10})`} class="keyword">
		<rect
			class="label-bg"
			x="0"
			y="0"
			width={labelBox.width}
			height={labelBox.height}
			rx={2}
			fill={color}
			stroke-width={2}
			opacity={tween.val}
		/>
		<text
			bind:this={textNode}
			class="highlighted-keyword"
			text-anchor="middle"
			dominant-baseline="middle"
			x={labelBox.width / 2}
			y={labelBox.height / 2 + 2}
			opacity={tween.val}
			fill="#FFF"
		/>
	</g>
{/if}

<style>
	.axis-label {
		font-size: 12px;
	}

	.highlighted-keyword {
		font-size: 14px;
		line-height: 16px;
		font-weight: 800;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		vertical-align: middle;
	}
</style>
