import * as d3 from 'd3';

import {
	clear,
	drawVerticalLine,
	drawPolyline,
	drawRect,
	drawText,
	drawHorizontalLine,
	drawWrappedText,
	drawImage,
} from './canvas.js';

import { convertDataToTimeSeries } from './data.js';
import { shapeColorsHex, shapeColorsName } from './colors.js';
import { text } from 'svelte/internal';

export function drawShareCard(canvas, trend) {
	const color = shapeColorsHex[trend.curatedShape];
	const theme = shapeColorsName[trend.curatedShape];

	const baseLayerId = `baseLayer-${theme}`;

	const scaler = 0.5;

	const start = new Date(2019, 0, 1);
	const end = new Date(2022, 0, 1);
	const width = 770 * scaler;
	const height = 575 * scaler;
	const xOffset = 195 * scaler;
	const yOffset = 195 * scaler;
	const margin = { top: 0, right: 0, bottom: 0, left: 0 };

	const formatMonth = d3.timeFormat('%b');
	const formatYear = d3.timeFormat('%Y');

	const x = d3
		.scaleTime()
		.domain([start, end])
		.range([xOffset + margin.left, xOffset + width - margin.right])
		.nice();

	const y = d3
		.scaleLinear()
		.domain([0, 100])
		.range([yOffset + height - margin.bottom, yOffset + margin.top]);

	clear(canvas);

	/*

	drawRect(canvas, {
		x: xOffset,
		y: yOffset,
		height,
		width,
		color: '#ccc'
	})

	*/

	drawImage(canvas, {
		id: baseLayerId,
		x: 0,
		y: 0,
		scale: 1 * scaler,
	});

	// Draw

	drawText(canvas, {
		text: trend.keyword.toUpperCase(),
		x: 70 * scaler,
		y: 100 * scaler,
		color: '#000',
		size: 40 * scaler,
		font: 'Manrope',
		weight: 700,
	});

	drawText(canvas, {
		text: trend.category.toUpperCase(),
		x: 70 * scaler,
		y: 130 * scaler,
		color: '#000',
		size: 24 * scaler,
		font: 'Manrope',
		weight: 400,
	});

	//

	drawTrend(canvas, {
		data: convertDataToTimeSeries(trend.timeline.values),

		x,
		xTicks: x.ticks().map((tick) => {
			return {
				label: formatMonth(tick),
				value: tick,
			};
		}),

		y,
		yTicks: [
			{ label: 'Low', value: 0 },
			{ label: 'Medium', value: 50 },
			{ label: 'High Search Interest', value: 100 },
		],

		chartWidth: width,
		chartHeight: height,

		color: color,
		lineWidth: 6,
	});
}

export function drawTrend(canvas, options) {
	const points = options.data.map((point) => {
		return [options.x(point.date), options.y(point.index)];
	});

	/*

	drawXAxis(canvas, {
		x: options.x,
		xTicks: options.xTicks,
		yOffset: options.y(0),
		color: '#dcdcdc',
	});

    */

	// Years

	/*

	options.xTicks.forEach((tick) => {
		if (tick.label !== 'Jan') return;

		const x = options.x(tick.value);
		const y = options.y(0);

		const text = tick.value.getFullYear();

		drawText(canvas, {
			x,
			y: y + 30,
			size: 8,
			text: text,
			textAlign: 'center',
			font: 'Manrope',
			weight: 600,
		});
	});

	drawYAxis(canvas, {
		y: options.y,
		yTicks: options.yTicks,
		xOffset: 60,
		color: '#dcdcdc',
	});

    */

	// let line = d3.line().curve(d3.curveCardinal).context(canvas.getContext("2d"));

	// Add curvature

	const context = canvas.getContext('2d');

	let line = d3.line().curve(d3.curveCardinal).context(context);

	context.lineCap = 'round';
	context.lineJoin = 'round';
	context.lineWidth = options.width || 6;
	context.strokeStyle = options.color || '#999';

	context.beginPath();
	line(points);
	context.stroke();

	/*

	drawPolyline(canvas, {
		points,
		color: options.color,
		width: options.width,
	});

	*/
}

export function drawXAxis(canvas, options) {
	options.xTicks.forEach((tick) => {
		const x = options.x(tick.value);
		const y = options.yOffset;

		drawVerticalLine(canvas, {
			x,
			y: y - 3,
			height: 6,
			color: options.color,
		});

		drawText(canvas, {
			x,
			y: y + 18,
			size: 8,
			text: tick.label,
			textAlign: 'center',
			font: 'Manrope',
			weight: 400,
		});
	});
}

export function drawYAxis(canvas, options) {
	options.yTicks.forEach((tick) => {
		const x = options.xOffset;
		const y = options.y(tick.value);

		drawHorizontalLine(canvas, {
			x: x,
			y,
			width: 360,
			color: options.color,
		});

		drawText(canvas, {
			x,
			y: y - 8,
			text: tick.label,
			size: 8,
			textBaseline: 'middle',
			font: 'Manrope',
			weight: 400,
		});
	});
}
