<script>
	import { onMount } from 'svelte';
	import { cubicInOut } from 'svelte/easing';

	import { shapeColorsName, trendColors } from '../../js/colors';

	import {
		currentFilters,
		currentSort,
		selectedTrend,
		shareTrend,
		showShare,
		sortedAndFiltered,
		trends,
	} from '../../stores/stores.js';

	import { downloadCanvasAsPNG } from '../../js/download.js';

	import Chart from './chart/Grid.Chart.svelte';

	let windowWidth = window.innerWidth;

	$: mobile = windowWidth < 600;

	$: selected = $selectedTrend;
	$: color = trendColors[$currentSort];

	let direction = null;
	let origin = getOrigin($selectedTrend, $sortedAndFiltered);

	let chartType = 'stacked';

	$: index = $sortedAndFiltered.findIndex((trend) => {
		if (!selected) return -1;

		return selected.keyword === trend.keyword;
	});

	$: {
		if (selected) {
			origin = getOrigin(selected, $sortedAndFiltered);
		} else {
			direction = null;
		}
	}

	function getOrigin(selected, sortedAndFiltered) {
		if (mobile || !selected) return { x: 50, y: 50 };

		const index = sortedAndFiltered.findIndex((trend) => {
			return selected.keyword === trend.keyword;
		});

		return {
			x: (index % 10) * 10,
			y: Math.floor(index / 10 + 1) * 10,
		};
	}

	// Transition
	const map = (from, to, t) => {
		return from + (to - from) * t;
	};

	function fade(node, params) {
		return {
			delay: params.delay || 0,
			duration: params.duration || 500,
			easing: cubicInOut,
			css: (t, u) => {
				return `opacity: ${t};`;
			},
		};
	}

	function scale(node, params) {
		return {
			delay: params.delay || 0,
			duration: params.duration || 500,
			easing: cubicInOut,
			css: (t, u) => {
				const x = map(params.x, 0, t);
				const y = map(params.y, 0, t);
				const s = map(0.1, 1, t);

				// Scale causes text shift in Chrome that only resolves after redraw.
				return `opacity: ${t}; transform: translate(${x}vw,${y}vh) scale(${s})`;
			},
		};
	}

	function slideIn(node, params) {
		return {
			delay: params.delay || 0,
			duration: params.duration || 500,
			easing: cubicInOut,
			css: (t, u) => {
				let fromX = 0;

				if (direction === 'next') fromX = 100;
				if (direction === 'previous') fromX = -100;

				const x = map(fromX, 0, t);

				return `opacity: ${1}; transform: translate(${x}vw,0)`;
			},
		};
	}

	function slideOut(node, params) {
		return {
			delay: params.delay || 0,
			duration: params.duration || 500,
			easing: cubicInOut,
			css: (t, u) => {
				let destX = 0;

				if (direction === 'next') destX = -100;
				if (direction === 'previous') destX = 100;

				const x = map(0, destX, 1 - t);

				return `opacity: ${t}; transform: translate(${x}vw,0)`;
			},
		};
	}

	const handleKey = (e) => {
		if (e.key === 'Escape') selectedTrend.set(null);
		if (e.code === 'ArrowLeft') previous();
		if (e.code === 'ArrowRight') next();
	};

	const next = (e) => {
		if (e) e.preventDefault();

		const index = $sortedAndFiltered.findIndex((trend) => {
			return selected.keyword === trend.keyword;
		});

		// if (index >= 90) return;

		direction = 'next';

		selectedTrend.set($sortedAndFiltered[index + 1]);
	};

	const previous = (e) => {
		if (e) e.preventDefault();

		const index = $sortedAndFiltered.findIndex((trend) => {
			return selected.keyword === trend.keyword;
		});

		// if (index <= 0) return;

		direction = 'previous';
		selectedTrend.set($sortedAndFiltered[index - 1]);
	};

	const share = () => {
		shareTrend.set(selected.keyword);

		const chart = document.getElementById('shareCanvas');

		downloadCanvasAsPNG(chart, `${selected.keyword}.png`);
	};
</script>

<svelte:window bind:innerWidth={windowWidth} on:keydown={handleKey} />

{#if selected}
	<div
		in:scale={{ x: origin.x, y: origin.y, direction }}
		out:scale={{ x: origin.x, y: origin.y, direction }}
		class="selected {selected ? 'open' : 'closed'} theme--{shapeColorsName[
			selected.curatedShape
		]}"
		style=""
	>
		<div class="controls" in:fade={{ delay: 500 }}>
			<!-- <div class="control__toggle">
				<button
					class={chartType === 'continuous' ? 'highlighted' : ''}
					on:click={() => (chartType = 'continuous')}
					>2019–2021</button
				>
				<button
					class={chartType === 'stacked' ? 'highlighted' : ''}
					on:click={() => (chartType = 'stacked')}>Jan–Dec</button
				>
			</div> -->

			<div class="navigation-controls">
				{#if $sortedAndFiltered[index - 1]}
					<div class="navigation__control">
						<button class="previous-control" on:click={previous}>
							{$sortedAndFiltered[index - 1].keyword}</button
						>
					</div>
				{/if}

				{#if $sortedAndFiltered[index + 1]}
					<div class="navigation__control">
						<button class="next-control" on:click={next}
							>{$sortedAndFiltered[index + 1].keyword}</button
						>
					</div>
				{/if}
			</div>

			<button
				class="share-control"
				on:click={share}
				aria-label="Share button"
			/>
			<button
				class="close-control"
				on:click={() => {
					if (mobile) {
						const gridElement = document.getElementById('grid');

						if (gridElement) gridElement.scrollIntoView();
					}

					selectedTrend.set(null);
				}}
				aria-label="Close button"
			/>
		</div>

		{#each [selected] as selected (selected.keyword)}
			<div class="wrapper" in:slideIn={{}} out:slideOut={{}}>
				<div class="control__toggle">
					<button
						class={chartType === 'continuous' ? 'highlighted' : ''}
						on:click={() => (chartType = 'continuous')}
						>2019–2021</button
					>
					<button
						class={chartType === 'stacked' ? 'highlighted' : ''}
						on:click={() => (chartType = 'stacked')}>Jan–Dec</button
					>
				</div>

				<div class="">
					<div class="header">
						<h2 class="header__keyword">{selected.keyword}</h2>
						<h3 class="header__category">
							{selected.rootCategory}
						</h3>
					</div>

					<Chart {chartType} {color} trend={selected} />
				</div>
			</div>
		{/each}
	</div>
{/if}

<style>
	.selected {
		position: fixed;
		z-index: 9999;

		width: calc(100vw);
		height: calc(100vh);
		padding: 80px 0 0 0;

		transition: opacity 1s, transform 1s;
		transform-origin: top left;
		/* background: rgba(255, 255, 255, 0.75); */

		background: #fff;
	}

	/* Controls */

	.controls button {
		transition: opacity 0.5s, transform 0.5s;

		opacity: 1;
		border: 0;
		background-color: transparent;
	}

	/* Control toggle */

	.control__toggle {
		position: absolute;
		z-index: 9999;
		top: calc(95px);
		left: 50%;

		display: flex;
		overflow: hidden;

		height: 30px;

		transform: translate(-50%, 0);

		background: #fff;

		font-size: 16px;
	}

	.control__toggle button {
		margin: 0;
		padding: 0 18px;

		transition: opacity 0.5s;
		white-space: nowrap;

		opacity: 1;
		border: 0;
		border-radius: 6px;
		background-color: transparent;

		font-weight: 800;
		line-height: 30px;
	}

	.control__toggle button.highlighted {
		color: #fff;
		border-radius: 5px;
	}

	.theme--blue .control__toggle button.highlighted {
		background: var(--blue);
	}

	.theme--red .control__toggle button.highlighted {
		background: var(--red);
	}

	.theme--yellow .control__toggle button.highlighted {
		background: var(--yellow);
	}

	/* Navigation */

	.navigation-controls button {
		width: 120px;

		text-align: center;

		font-size: 16px;
		font-weight: 700;
	}

	.previous-control,
	.next-control,
	.close-control,
	.share-control {
		position: absolute;
		z-index: 999;
	}

	.previous-control,
	.next-control {
		height: 48px;
	}

	.previous-control {
		top: calc(50vh - 60px);
		left: 36px;
	}

	.next-control {
		top: calc(50vh - 60px);
		right: 36px;
	}

	.previous-control::after,
	.next-control::after {
		position: absolute;
		top: 100%;
		left: 50%;

		width: 48px;
		height: 48px;

		content: '';
		transform: translate(-50%, 0);

		background-repeat: no-repeat;
		background-position: center;
	}

	.previous-control::after {
		background-image: url('./images/left.svg');
	}

	.next-control::after {
		background-image: url('./images/right.svg');
	}

	/* Close and share */

	.close-control,
	.share-control {
		width: 55px;
		height: 55px;

		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
	}

	.close-control {
		top: 60px;
		right: calc(10vw + 60px - 12px);

		background-image: url('./images/close.svg');
		background-size: 60px;
	}

	.share-control {
		top: 60px;
		right: calc(10vw + 120px - 12px);

		background-image: url('./images/download.svg');
		background-size: 60px;
	}

	.wrapper {
		position: absolute;
		left: 10vw;

		width: 80vw;
		/* padding: 24px; */

		/* border: 1px solid #dcdcdc; */
		/* background: #fff; */
	}

	/* Header */

	.header {
		margin: 0 auto 30px auto;
		padding: 0 30px;
	}

	.header__keyword {
		margin: 0;

		letter-spacing: 0.025em;
		text-transform: uppercase;

		font-size: 42px;
		font-weight: 800;
		line-height: 48px;
	}

	.header__category {
		margin: 0;

		letter-spacing: 0.025em;
		text-transform: uppercase;

		font-size: 24px;
		font-weight: 300;
		line-height: 30px;
	}

	/* Hover */

	@media (hover: hover) {
		.controls button:hover {
			/* transform: scale(1.25); */

			opacity: 0.5;
		}

		.control__toggle button:hover {
			opacity: 0.5;
		}
	}

	/* Mobile */

	@media screen and (max-width: 600px) {
		.selected {
			position: fixed;
			top: 0;
			left: 0;
		}

		.wrapper {
			/* position: relative; */
			top: 60px;
			right: 0;
			left: 0;

			width: 100%;
			/* padding: 0 18px; */
		}

		.header {
			/* text-align: center; */
			height: 150px;
			margin: 12px 0 0 0;

			font-size: 36px;
		}

		.header__keyword {
			font-size: 30px;
			line-height: 1;
		}

		.header__category {
			font-size: 18px;
		}

		.model-score-container {
			position: absolute;
			top: 90px;

			width: 80vw;
			margin: 0 auto;
		}

		/* Navigation controls */

		.navigation-controls {
			position: absolute;
			bottom: 0;

			display: flex;

			width: 100%;
			height: 150px;

			border-top: 2px solid #f4f4f4;
		}

		.navigation__control {
			position: relative;

			display: flex;
			align-items: center;
			flex: 50% 0 0;
			justify-content: center;

			height: 100%;
			padding: 18px 0 0 0;
		}

		.navigation__control:not(:last-child) {
			border-right: 2px solid #f4f4f4;
		}

		.previous-control,
		.next-control {
			top: auto;
			right: auto;
			left: auto;
		}

		.previous-control::after {
			top: -48px;
			left: 50%;
		}

		.next-control::after {
			top: -48px;
			left: 50%;
		}

		/* Share and close */

		.close-control {
			top: 12px;
			right: 36px;
		}

		.share-control {
			top: 12px;
			right: 96px;
		}
	}
</style>
