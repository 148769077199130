<script>
	import { onMount } from 'svelte';

	import { sortAndFilter } from '../../js/sortAndFilter.js';

	import {
		gridInView,
		trends,
		selectedTrend,
		sortedAndFiltered,
		currentSort,
		currentFilters,
	} from '../../stores/stores.js';

	let windowWidth = window.innerWidth;

	$: mobile = windowWidth < 600;

	let showCategories = false;
	let showControlsOnMobile = false;

	const sorts = [
		{ label: 'All', id: 'all' },
		{ label: 'New Normal', id: 'sustained' },
		{ label: 'Unusual', id: 'spike' },
		{ label: 'Normal', id: 'noChange' },
	];

	$: categories = getCategories(sortAndFilter($trends, [], $currentSort));

	$: {
		if ($currentSort) currentFilters.set([]);
	}

	const setFilter = (category) => {
		return (event) => {
			event.preventDefault();

			if (!category || currentFilters[0] === category) {
				currentFilters.set([]);
			} else {
				currentFilters.set([category]);
			}

			showCategories = false;

			if (mobile) scrollGridIntoView();
		};
	};

	const setSort = (sort) => {
		return (event) => {
			event.preventDefault();

			currentSort.set(sort.id);

			if (mobile) scrollGridIntoView();
		};
	};

	function getCategories(trends) {
		if (!trends) return [];

		const categories = {};

		trends.forEach((trend) => {
			const category = trend.rootCategory; // changed to root category rather than more specific one

			if (!categories[category]) categories[category] = 0;

			categories[category] = categories[category] + 1;
		});

		return Object.keys(categories)
			.sort()
			.map((key) => {
				return {
					id: key,
					count: categories[key],
				};
			});
	}

	function scrollGridIntoView() {
		const gridElement = document.getElementById('grid');

		if (gridElement) gridElement.scrollIntoView();
	}

	onMount(() => {});
</script>

<div
	class="grid__controls grid__controls--{$gridInView
		? 'in-view'
		: 'out-view'} grid__controls--{showControlsOnMobile
		? 'show-mobile'
		: 'hide-mobile'}"
>
	<!-- <div class="" /> -->

	<button
		aria-label="Show grid controls"
		class="grid__mobile-control-open"
		on:click={() => {
			showControlsOnMobile = true;
		}}
	/>

	<div class="grid__categories-and-sort">
		<button
			aria-label="Close grid controls"
			class="grid__mobile-control-close"
			on:click={() => {
				if (mobile) {
					const gridElement = document.getElementById('grid');

					if (gridElement) gridElement.scrollIntoView();
				}

				showControlsOnMobile = false;
			}}
		/>

		<div class="grid__category__controls">
			<button
				aria-label="Show categories"
				class="grid__category__toggle"
				on:click={() => (showCategories = !showCategories)}
				>{$currentFilters[0] || 'All Categories'}</button
			>

			<div
				class="grid__categories {showCategories
					? 'open'
					: 'closed'} ignore-scroll"
			>
				<button
					aria-label="Show all categories"
					class={!$currentFilters.length ? 'selected' : ''}
					on:click={setFilter(null)}>All Categories</button
				>

				{#each categories as category}
					<button
						aria-label="Set filter to {category.id}"
						class={$currentFilters.includes(category.id)
							? 'selected'
							: ''}
						on:click={setFilter(category.id)}
						>{category.id}
						<!-- <span class="count">({category.count})</span -->
					</button>
				{/each}
			</div>
		</div>

		<div class="grid__sort__controls grid__sort__controls--{$currentSort}">
			{#each sorts as s}
				<button
					aria-label="Set sort to category {s.label}"
					class="{s.id} {$currentSort === s.id ? 'selected' : ''}"
					on:click={setSort(s)}>{s.label}</button
				>
			{/each}
		</div>
	</div>
</div>

<style>
	.grid__controls {
		overflow-y: hidden;

		width: 100%;
		padding: 0 24px;

		pointer-events: none;

		opacity: 0;
		color: #000;

		font-size: 16px;
		font-weight: 800;
		line-height: 24px;
	}

	/* Grid in view */

	.grid__controls.grid__controls--in-view {
		pointer-events: all;

		opacity: 1;
	}

	/*  */

	.grid__categories-and-sort {
		display: flex;
		justify-content: space-between;
	}

	/* Sort controls */

	.grid__control__label {
		font-weight: 500;
	}

	.grid__controls button {
		margin: 0;
		padding: 0;

		transition: opacity 0.5s;

		opacity: 1;
		border: 0;
		background: transparent;

		font-size: inherit;
		/* font-weight: inherit; */
	}

	/*  */

	.grid__sort__controls {
		position: fixed;
		top: 9px;
		left: 50%;

		overflow: hidden;

		height: 30px;

		transform: translate(-50%, 0);

		border-radius: 6px;
	}

	.grid__sort__controls button {
		/* width: 144px; */
		width: auto;
		height: 100%;
		padding: 0 20px;

		cursor: pointer;
		letter-spacing: 0.075em;
		text-transform: uppercase;

		border-radius: 6px;

		font-size: 14px;
		font-weight: 700;
	}

	.grid__sort__controls button.selected {
		background: #f4f4f4;
		/* height: 30px; */
		/* border-radius: 5px; */
	}

	/*  */

	.grid__sort__controls .noChange {
		color: var(--red);
	}

	.grid__sort__controls .spike {
		color: var(--yellow);
	}

	.grid__sort__controls .sustained {
		color: var(--blue);
	}

	/* Category controls  */

	.grid__category__controls {
		position: relative;

		text-align: right;
	}

	.grid__category__toggle {
		font-size: 12px;
		font-weight: 700;
		line-height: 30px;
	}

	.grid__category__toggle::before {
		position: absolute;
		left: -30px;

		width: 30px;
		height: 30px;

		content: '';

		background-image: url('./images/filter.svg');
		background-position: center;
		background-size: 36px;
	}

	.grid__categories {
		position: fixed;
		top: 48px;
		left: 0;

		overflow-x: hidden;
		overflow-y: auto;
		/* width: 240px; */

		max-height: calc(100vh - 48px);

		border: 1px solid #f4f4f4;
		background: #fff;
	}

	.grid__categories.closed {
		display: none;
	}

	.grid__categories button {
		display: block;

		width: 100%;
		height: 48px;
		margin-left: 5px;
		padding: 0 20px 0 20px;

		text-align: left;
		white-space: nowrap;

		border-bottom: 1px solid #f4f4f4;

		font-size: 16px;
		font-weight: 400;
	}

	.grid__categories button.selected {
		font-weight: 700;
	}

	.grid__categories .count {
		font-size: 0.8em;
	}

	/*  */

	button.grid__mobile-control-open {
		position: fixed;
		z-index: 999;
		top: 6px;
		left: 30px;

		display: none;

		width: 36px;
		height: 36px;

		background-image: url('./images/filter.svg');
		background-position: center;

		line-height: 36px;
	}

	button.grid__mobile-control-close {
		position: fixed;
		z-index: 999;
		top: 6px;
		right: 30px;

		display: none;

		width: 36px;
		height: 36px;

		background-image: url('./images/close.svg');
		background-position: center;
		background-size: 36px;

		line-height: 36px;
	}

	/* Hover */

	@media (hover: hover) {
		.grid__controls button:hover {
			opacity: 0.5;
		}
	}

	/* Mobile */

	@media screen and (max-width: 780px) {
		button.grid__mobile-control-open,
		button.grid__mobile-control-close {
			display: block;
		}

		.grid__categories-and-sort {
			position: fixed;
			z-index: 99999;
			top: 48px;
			right: 0;
			left: 0;

			overflow: hidden;
			flex-direction: column-reverse;
			justify-content: flex-end;

			width: 100vw;
			height: calc(100vh - 48px);

			transition: transform 0.5s;
			transform: translate(0, 0);
			/* height: calc(100vh - 48px); */

			background: #fff;
		}

		/*  */

		.grid__controls--hide-mobile .grid__categories-and-sort {
			transform: translate(0, calc(-100vh - 48px));
			pointer-events: none;
		}

		/*  */

		.grid__sort__controls {
			position: relative;
			top: auto;
			left: auto;

			width: 100%;
			height: 240px;

			transform: translate(0, 0);
			text-align: left;

			border: none;
			border-radius: 0;

			/* border-bottom: 1px solid #000; */
		}

		.grid__sort__controls button,
		.grid__sort__controls span {
			display: block;

			width: 100%;
			height: 48px;
			margin: 0;
			padding: 0 30px;

			border-radius: 0;

			font-size: 18px;
		}

		.grid__sort__controls > * {
			display: block;

			width: 100%;
			height: 48px;
			margin: 0;
			padding: 0 30px;

			text-align: left;

			line-height: 48px;
		}

		.grid__sort__controls button.selected {
			color: #000;
			background: #dcdcdc;
		}

		.grid__sort__controls button.sustained.selected {
			color: #fff;
			background: var(--blue);
		}

		.grid__sort__controls button.spike.selected {
			color: #fff;
			background: var(--yellow);
		}

		.grid__sort__controls button.noChange.selected {
			color: #fff;
			background: var(--red);
		}

		/* Category controls */

		.grid__category__controls {
			text-align: left;
		}

		button.grid__category__toggle {
			display: block;

			width: 100%;
			height: 48px;
			padding: 0 30px;

			text-align: left;

			border-top: 1px solid #000;
			border-bottom: 1px solid #dcdcdc;

			line-height: 48px;
		}

		.grid__categories.open,
		.grid__categories.closed {
			position: relative;
			top: auto;
			right: auto;

			display: block;

			max-height: calc(100vh - 288px);
		}

		.grid__categories {
			border: 0;
		}

		.grid__categories button {
			display: block;

			height: 48px;
			margin: 0;
			padding: 0 30px;

			font-size: 18px;
			line-height: 48px;
		}
	}

	@media screen and (max-width: 1080px) {
		.grid__sort__controls button {
			/* width: auto; */
		}
	}
</style>
