<script>
	import * as d3 from 'd3';

	import { onMount } from 'svelte';
	import { cubicInOut } from 'svelte/easing';

	import ContinuousChart from './Grid.Chart.Continuous.svelte';
	import StackedChart from './Grid.Chart.Stacked.svelte';

	export let chartType;
	export let color;
	export let trend;

	let width = 960;
	let height = 540;

	function fade(node, params) {
		return {
			delay: params.delay || 0,
			duration: params.duration || 500,
			easing: cubicInOut,
			css: (t, u) => {
				return `opacity: ${t};`;
			},
		};
	}

	onMount(() => {});
</script>

<div class="grid__chart" bind:offsetWidth={width} bind:offsetHeight={height}>
	{#if chartType === 'continuous'}
		<div class="chart" transition:fade>
			<ContinuousChart {color} {trend} />
		</div>
	{:else if chartType === 'stacked'}
		<div class="chart" transition:fade>
			<StackedChart {color} {trend} />
		</div>
	{/if}
</div>

<style>
	.grid__chart {
		width: 100%;
		max-width: 80vw;
		height: calc(100vh - 264px);
		margin: auto;
	}

	.chart {
		position: absolute;

		width: 100%;
		height: 100%;
	}

	@media screen and (max-width: 600px) {
		.grid__chart {
			max-width: none;
			height: calc(100vh - 384px);
		}
	}
</style>
