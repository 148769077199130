<script>
	import textContent from '../../data/textContent.json';
	const methodsText = textContent.sections.find(
		(d) => d.id === 'methods'
	).text;
</script>

<div class="about-container .ignore-scroll">
	<div class="methods-container box-container">
		<div class="box-content">
			<h1>About</h1>
			<div class="methods-content">
				{@html methodsText}
			</div>
		</div>
	</div>
	<div class="attrib-container box-container">
		<div class="box-content" />
	</div>
	<div class="share-container box-container">
		<div class="box-content">
			<div class="share-links-container">
				<h1>Share</h1>
				<ul>
					<li>
						<a
							href="https://twitter.com/intent/tweet?url=http%3A%2F%2Fthenewnormal.is"
							target="_blank"
							rel="noopener">Twitter</a
						>
					</li>
					<li>
						<a
							href="https://www.facebook.com/sharer.php?u=http%3A%2F%2Fthenewnormal.is"
							target="_blank"
							rel="noopener">Facebook</a
						>
					</li>
					<li>
						<a
							href="https://www.linkedin.com/sharing/share-offsite/?url=https://thenewnormal.is"
							target="_blank"
							rel="noopener">LinkedIn</a
						>
					</li>
				</ul>
			</div>

			<div class="credits-container">
				<h1>Credits</h1>
				"The New Normal" is a collaboration between&nbsp<a
					href="https://www.schemadesign.com/"
					target="_blank"
					rel="noopener">Schema Design</a
				>,
				<a
					href="https://trends.google.com/trends/?geo=US"
					target="_blank"
					rel="noopener">Google Trends</a
				>, and
				<a href="https://www.axios.com/" target="_blank" rel="noopener"
					>Axios</a
				>. Art direction
				<span style="white-space: nowrap;"
					>by
					<a
						href="http://albertocairo.com/"
						target="_blank"
						rel="noopener">Alberto Cairo</a
					>.</span
				>
			</div>
		</div>
	</div>
</div>

<style>
	.about-container {
		display: grid;

		width: 80vw;
		height: 100%;
		margin: 0 10vw;
		padding: 10vh 0;

		grid-template-columns: 60vw 20vw;
		grid-template-rows: auto;
		grid-template-areas: 'methods share';
		grid-auto-rows: min-content;
	}

	.attrib-container {
		grid-area: attrib;
	}

	.box-container {
		display: flex;
		/* align-items: center; */
	}

	.box-content {
		font-size: 18px;
		font-weight: 400;
		line-height: 25px;
	}

	.share-container {
		padding-bottom: 10vh;

		grid-area: share;
	}

	.share-links-container {
		display: flex;
		flex-direction: column;
	}

	.credits-container {
		margin-top: 40px;
	}

	.methods-container {
		padding-right: 5vw;
		padding-bottom: 20%;

		grid-area: methods;
	}

	h1 {
		margin-bottom: 10px;

		font-size: 18px;
		font-style: bold;
		line-height: 24px;
	}

	ul {
		margin: 0;
		padding: 0;

		list-style-type: none;
	}

	a {
		transition: border-bottom 0.2s, color 0.2s;
		text-decoration: none;

		border-bottom: 1px solid black;
	}

	@media (hover: hover) {
		a:hover {
			transition: border-bottom 0.2s, color 0.2s;

			color: var(--blue);
			border-bottom: 1px solid transparent;
		}
	}

	@media screen and (max-width: 600px) {
		.about-container {
			width: 100vw;
			margin: 0px;
			padding: 20px;
			/* overflow-y: scroll; */

			grid-template-columns: auto;
			grid-template-rows: auto;
			grid-template-areas:
				'methods'
				'share';
		}

		.share-container {
			margin-top: 40px;
		}

		.methods-container {
			padding-right: 0px;
			/* overflow-y: none; */
			padding-bottom: 0px;
		}
	}
</style>
