const modelLookup = {
	noChange: 'Normal',
	spike: 'Unusual',
	sustained: 'New Normal',
};

export function sortAndFilter(trends, filters, sort) {
	if (!trends) return [];

	return trends
		.slice()
		.filter((trend) => {
			// Sort filtering
			if (sort === 'all') return true;

			return trend.curatedShape === modelLookup[sort];
		})
		.filter((trend) => {
			// Category filtering

			if (!filters.length) return true;

			return filters.some((filter) => filter === trend.rootCategory);
		})
		.sort((a, b) => {
			return a['keyword'].localeCompare(b['keyword']);

			if (sort === 'all') return a['keyword'].localeCompare(b['keyword']);

			return a.model[sort] < b.model[sort] ? 1 : -1;
		})
		.map((trend) => {
			return {
				...trend,
				data: trend.timeline.values,
			};
		});
	// .slice(0, 100);
}
