<script>
	// import * as d3 from 'd3';

	import { onMount } from 'svelte';
	import { selectedTrend } from '../../stores/stores.js';

	import { downloadCanvasAsPNG } from '../../js/download.js';

	import ShareChartCanvas from './ShareChartCanvas.svelte';

	const download = () => {
		downloadCanvasAsPNG(
			document.getElementById('shareCanvas'),
			`${$selectedTrend.keyword}.png`
		);
	};

	onMount(() => {});
</script>

<div class="share__chart">
	
	<div class="share__controls">
		<button on:click={download}>Download</button>
		<!-- <button on:click={() => showShare.set(false)}>Close</button> -->
	</div>
	
	<ShareChartCanvas />

	<img alt="" class='shareImage' id='shareLogos' src='./images/logos.png'/>
	<img alt="" class='shareImage' id='baseLayer-blue' src='./assets/sharing/sharing-blue.png'/>
	<img alt="" class='shareImage' id='baseLayer-red' src='./assets/sharing/sharing-red.png'/>
	<img alt="" class='shareImage' id='baseLayer-yellow' src='./assets/sharing/sharing-yellow.png'/>
</div>

<style>
	.share__chart {
		position: fixed;
		z-index: 9999;
		top: 90px;
		left: 90px;

		/* display: flex; */
		display: none;
		align-items: center;
		justify-content: center;

		border-radius: 6px;
		background: #fff;
	}

	.shareImage {
		display: none;
	}

	.share__controls {
		position: absolute;
		top: 0;
		right: -90px;
	}
</style>
