export function clear(canvas) {
	const context = canvas.getContext('2d');

	context.fillStyle = 'rgb(255, 255, 255)';
	context.fillRect(0, 0, canvas.width, canvas.height);
}

export function drawHorizontalLine(canvas, options) {
	drawPolyline(canvas, {
		points: [
			[options.x, options.y],
			[options.x + options.width, options.y],
		],
		color: options.color,
		lineWidth: options.lineWidth,
	});
}

export function drawVerticalLine(canvas, options) {
	drawPolyline(canvas, {
		points: [
			[options.x, options.y],
			[options.x, options.y + options.height],
		],
		color: options.color,
		lineWidth: options.lineWidth,
	});
}

export function drawPolyline(canvas, options) {
	const defaults = {
		points: [],
		color: '#999',
		lineWidth: 6,
	};

	options = Object.assign({}, defaults, options);

	const context = canvas.getContext('2d');

	context.beginPath();
	// context.miterLimit = style.width / 2;

	context.lineJoin = 'round';
	context.lineWidth = options.lineWidth || 1;
	context.strokeStyle = options.color || '#999';

	options.points.forEach(([x, y], index) => {
		if (index === 0) {
			context.moveTo(x, y);
		} else {
			context.lineTo(x, y);
		}
	});

	context.stroke();
}

export function drawImage(canvas, options = {}) {
	const defaults = {
		x: 0,
		y: 0,
		scale: 1,
	};

	options = Object.assign({}, defaults, options);

	if (!options.id) return;

	const image = document.getElementById(options.id);

	if (!image) return;

	//

	const context = canvas.getContext('2d');

	context.drawImage(
		image,
		0,
		0,
		image.width,
		image.height,
		options.x,
		options.y,
		image.width * options.scale,
		image.height * options.scale
	);
}

export function drawRect(canvas, options = {}) {
	const defaults = {
		x: 0,
		y: 0,
		width: 100,
		height: 100,
		color: '#ccc',
	};

	options = Object.assign({}, defaults, options);

	const context = canvas.getContext('2d');

	context.fillStyle = options.color;
	context.fillRect(options.x, options.y, options.width, options.height);

	context.strokeStyle = options.color || '#999';
}

export function drawText(canvas, options) {
	const defaults = {
		x: 0,
		y: 0,
		text: 'Text',
		font: 'sans-serif',
		color: '#000',
		size: 12,
		weight: 400,
		textAlign: 'left',
		textBaseline: 'alphabetic',
	};

	options = Object.assign({}, defaults, options);

	const context = canvas.getContext('2d');

	context.textAlign = options.textAlign;
	context.textBaseline = options.textBaseline;
	context.font = `${options.weight} ${options.size}px ${options.font}`;
	context.fillStyle = options.color;
	context.fillText(options.text, options.x, options.y);
}

export function drawWrappedText(canvas, options) {
	const defaults = {
		x: 0,
		y: 0,
		text: 'Text',
		font: 'sans-serif',
		color: '#000',
		size: 12,
		weight: 400,
		textAlign: 'left',
		textBaseline: 'alphabetic',
		lineLength: 36,
		lineHeight: 12 * 1.4,
	};

	options = Object.assign({}, defaults, options);

	const context = canvas.getContext('2d');

	let lines = splitIntoLines(options.text, options.lineLength);

	lines.forEach((line, i) => {
		context.textAlign = options.textAlign;
		context.textBaseline = options.textBaseline;
		context.font = `${options.weight} ${options.size}px ${options.font}`;
		context.fillStyle = options.color;
		context.fillText(line, options.x, options.y + options.lineHeight * i);
	});
}

function splitIntoLines(string, characters) {
	let lines = [[]];

	let words = string.split(' ');

	words.forEach((word) => {
		let last = lines[lines.length - 1];
		let lastLength = last.join(' ').length;

		if (lastLength + word.length < characters) {
			last.push(word);
		} else {
			lines.push([word]);
		}
	});

	lines = lines.map((line) => line.join(' '));

	return lines;
}
