<script>
	import * as d3 from 'd3';

	import { onMount } from 'svelte';
	import { LayerCake, Svg } from 'layercake';
	import gsap from 'gsap';

	import { trends } from '../../stores/stores.js';
	import { getTrendByKeyword, getTrendLineData } from '../../js/data.js';
	import { shapeCardsData } from '../../data/shapeCardsData';
	import { randBw } from '../../js/utilities';

	import Chart from './Shapes.chart.svelte';
	export let isInView = false;

	let height, width;

	// --- define content for shapes --------------
	$: shapes = shapeCardsData.map((shape) => ({
		...shape,
		trendData: getTrendLineData(getTrendByKeyword($trends, shape.keyword)),
	}));

	// --- Animate Timeline ----------------------
	let tl = gsap.timeline({ paused: true });
	let node;

	// onMount(() => {
	// 	// get parent dimensions
	// 	const nodeRect = node.getBoundingClientRect();

	// 	// define where icons should cluster
	// 	const targetX = nodeRect.width * 0.5;
	// 	const xWiggle = nodeRect.width * 0.3;
	// 	const targetY = -height * 0.75;

	// 	const icons = gsap.utils.toArray('.shape-icon');
	// 	icons.forEach((icon, i) => {
	// 		const iconX = icon.getBoundingClientRect().x;
	// 		const xMove = targetX - iconX;

	// 		tl.from(
	// 			icon,
	// 			{
	// 				y: targetY + randBw(-150, 150),
	// 				x: xMove + randBw(-xWiggle, xWiggle),
	// 				rotation: 360 + randBw(-30, 30),
	// 				duration: 1,
	// 				scale: 10,
	// 				repeatRefresh: true,
	// 				ease: 'back.inOut',
	// 			},
	// 			i * 0.1 // <- stagger each shapes start
	// 		);
	// 	});
	// });

	// $: if (isInView) {
	// 	tl.play();
	// } else {
	// 	tl.reverse();
	// }
</script>

<div
	bind:this={node}
	bind:clientHeight={height}
	bind:clientWidth={width}
	class="shapes-container"
>
	<div class="cards-container">
		{#each shapes as shape, i}
			<div class="shape-card">
				<div class="shape-heading">
					<div class="shape-icon-container">
						<img
							class={`shape-icon ${shape.shapeName}-icon`}
							src={`./assets/${shape.icon}`}
							alt=""
						/>
					</div>
					<h1>{shape.display}</h1>
				</div>

				<p class="description">{@html shape.description}</p>

				<div class="chart-container">
					<LayerCake
						xScale={d3.scaleTime()}
						xDomain={[new Date(2019, 0, 1), new Date(2021, 11, 30)]}
						yDomain={[0, 80]}
						padding={{ top: 15, bottom: 30, left: 15 }}
					>
						<Svg>
							<Chart
								{isInView}
								chartIdx={i}
								keyword={shape.keyword}
								color={shape.color}
								trend={shape.trendData}
							/>
						</Svg>
					</LayerCake>
				</div>
			</div>
		{/each}
	</div>
</div>

<style lang="scss">
	.shapes-container {
		margin: 0 10vw;
		padding-top: 10vh;
	}

	.cards-container {
		width: 100%;
		height: 100%;
		padding-bottom: 20vh;
		display: flex;
		justify-content: space-between;
	}

	.title {
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}

	.shape-card {
		width: 20vw;
		max-width: 370px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0;

		.shape-heading {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			h1 {
				font-weight: 800;
				font-size: 28px;
				line-height: 125%;
				margin: 0 10px;
			}

			.shape-icon-container {
				z-index: -1;
				width: 40px;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.unusual-icon {
				transform: translate(
					0,
					-3px
				); // <- needs to be adjusted up to look centered
			}

			img {
				width: 30px;
			}
		}

		.description {
			font-size: 19px;
			min-height: 70px;
			font-weight: 500;
			line-height: 1.3em;

			:global(span) {
				font-weight: 500;
			}

			:global(.normal) {
				color: var(--red);
			}

			:global(.unusual) {
				color: var(--yellow);
			}

			:global(.new-normal) {
				color: var(--blue);
			}
		}

		.chart-container {
			width: 100%;
			height: 280px;
			align-self: flex-end;
			margin: 0;
		}
	}

	p {
		font-size: 18px;
		font-weight: 400;
		letter-spacing: 1.25;
	}

	@media screen and (max-width: 600px) {
		.cards-container {
			flex-wrap: wrap;
		}

		.shape-card {
			width: 100%;
			height: 70vh;
			justify-content: flex-start;
		}
	}
</style>
