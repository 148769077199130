<script>
	import { onMount } from 'svelte';

	let width = window.innerWidth;
	let height = window.innerHeight;

	let headerHeight = 48;

	$: mobile = width < 600;
	$: xCount = mobile ? 2 : 10;
	$: yCount = mobile ? 6 : 10;

	$: xLines = [...new Array(xCount)].map((_, i) => {
		const increment = width / xCount;

		return (i + 1) * increment;
	});

	$: yLines = [...new Array(yCount)].map((_, i) => {
		const increment = mobile ? 120 : (height - headerHeight) / yCount;

		return headerHeight + (i + 1) * increment;
	});

	onMount(() => {});
</script>

<svelte:window bind:innerHeight={height} bind:innerWidth={width} />

<div class="background">
	<svg width="100%" height="100%">
		<g>
			{#each yLines as line}
				<line
					class="axis__line"
					x1="0"
					y1="{line}px"
					x2="100%"
					y2="{line}px"
				/>
			{/each}
		</g>

		<g>
			{#each xLines as line}
				<line
					class="axis__line"
					x1="{line}px"
					y1="0"
					x2="{line}px"
					y2="100%"
				/>
			{/each}
		</g>
	</svg>
</div>

<style>
	header {
		position: fixed;
		z-index: 9999;
		top: 0px;

		display: flex;
		justify-content: space-between;

		width: 100%;
		height: 48px;
		padding: 12px 36px;

		letter-spacing: 0;

		color: #000;
		border-bottom: 1px solid #dcdcdc;
		/* background: #fff; */

		font-size: 16px;
		font-weight: 800;
		line-height: 24px;
	}

	header button {
		margin: 0;
		padding: 0;

		border: 0;
		background: transparent;

		font-size: inherit;
		font-weight: inherit;
	}

	.background {
		position: fixed;
		z-index: -1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		width: 100vw;
		height: 100vh;
	}

	.background svg {
		width: 100%;
		height: 100%;
	}

	.axis__line {
		stroke-width: 2;
		stroke: #f4f4f4;
	}
</style>
