<script>
	import { onMount } from 'svelte';

	import { trendColors } from '../../js/colors.js';

	import {
		trends,
		currentSort,
		currentFilters,
		selectedTrend,
		sortedAndFiltered,
	} from '../../stores/stores.js';

	import Sparkline from './Grid.Sparkline.svelte';

	export let isInView = true;

	let windowWidth = window.innerWidth;
	let windowHeight = window.innerHeight;

	$: mobile = windowWidth < 600;

	$: trendsInGrid = mobile ? $sortedAndFiltered : $trends;
	$: trendDimensions = getDimensions(mobile, windowWidth, windowHeight);

	$: columns = mobile ? 10 : 10;
	$: rows = mobile ? 10 : 10;

	// $: visible = columns * rows;
	// $: trendHeight = windowHeight - 48 / columns;

	$: positions = getPositions($sortedAndFiltered, windowWidth, windowHeight);

	$: color = $currentSort ? trendColors[$currentSort] : '#000';

	function getDimensions(mobile, width, height) {
		if (mobile) {
			return {
				width: windowWidth / 2,
				height: 120,
			};
		} else {
			return {
				width: windowWidth / 10,
				height: (windowHeight - 48) / 10,
			};
		}
	}

	function getPositions(sorted) {
		const positions = {};

		sorted.forEach((trend, i) => {
			const col = i % columns;
			const row = Math.floor(i / columns);

			positions[trend.keyword] = { index: i, col, row };
		});

		return positions;
	}

	function getTransform(positions, trend, mobile, isInView) {
		if (mobile) return `translate(0px, 0px)`;

		let keyword = trend.keyword;

		let offScreenIndex = (trend.keyword.charCodeAt(0) - 65) % 2;

		let w = windowWidth;
		let h = windowHeight;
		let increment = (h - 48) / 10;

		let offScreenPositions = [
			[w * -0.5, h * Math.random()],
			// [w * 0.5, h * -0.5],
			[w * 1.5, h * Math.random()],
			// [w * 0.5, h * 1.5],
		];

		let [x, y] = offScreenPositions[offScreenIndex];

		if (isInView && positions[keyword]) {
			x = w * (positions[keyword].col / 10);
			y = 48 + increment * positions[keyword].row;
		}

		return `translate(${Math.floor(x)}px, ${Math.floor(y)}px)`;
	}

	onMount(() => {});
</script>

<svelte:window bind:innerHeight={windowHeight} bind:innerWidth={windowWidth} />

<div class="trends" style="height:100%">
	{#each trendsInGrid as trend (trend.keyword)}
		<div
			class="trend"
			on:click={(e) => {
				selectedTrend.set(trend);
			}}
			style="transform: {getTransform(
				positions,
				trend,
				mobile,
				isInView
			)}"
		>
			<div class="trend__keyword">{trend.keyword}</div>

			<Sparkline
				{color}
				keyword={trend.keyword}
				width={trendDimensions.width}
				height={trendDimensions.height}
			/>
		</div>
	{/each}
</div>

<style>
	.trends {
		/* min-height: 100vh; */
	}

	.trend {
		position: absolute;

		overflow: hidden;

		width: 10vw;
		height: calc((100vh - 48px) / 10);

		cursor: pointer;
		transition: background 0.5s, transform 1s;

		border: 1px solid #f4f4f4;
		background: #fff;
	}

	.trend:hover {
		background: #f4f4f4;
	}

	.trend__keyword {
		position: absolute;

		padding: 5px 9px 9px 9px;

		letter-spacing: 0.05em;
		text-transform: uppercase;

		font-size: 11px;
		font-weight: 700;
		line-height: 12px;
	}

	/* Mobile */

	@media screen and (max-width: 600px) {
		.trends {
			display: flex;
			flex-wrap: wrap;

			padding: 48px 0 0 0;

			background: #fff;
		}

		.trend {
			position: relative;

			width: calc(100% / 2);
			height: 120px;
			/* height: calc((100vh - 48px) / 6); */

			background: #fff;
		}
	}
</style>
