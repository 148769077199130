<script>
	import * as d3 from 'd3';

	import { getContext } from 'svelte';
	import gsap from 'gsap';
	import { trends } from '../../stores/stores.js';
	import mq from '../../stores/mq.js';
	import Keyword from './Cover.keyword.svelte';

	import {
		shuffle,
		getTrendByKeyword,
		getTrendLineData,
	} from '../../js/data.js';
	import { trendColors } from '../../js/colors';

	$: isMobile = $mq.sm;

	const covidStartDate = new Date(2020, 2, 11); // <- March 11 2020, WHO declares a pandemic
	const { width, height, xScale, yScale } = getContext('LayerCake');

	$: pathFn = d3 // <-- make pathFn reactive to changes in width,height (which affect $xScale,$yScale)
		.line()
		.curve(d3.curveBasis)
		.x((d) => $xScale(d.date))
		.y((d) => $yScale(d.index));

	$: areaFn = d3
		.area()
		.curve(d3.curveBasis)
		.x((d) => $xScale(d.date))
		.y1((d) => $yScale(d.index))
		.y0($height);

	// --- screen size based params ---------------------------------------------
	let strokeWidth = 12;
	let showYearTicks = true;
	$: if ($mq.sm) {
		strokeWidth = 6;
		showYearTicks = false;
	}

	// --- set up year axes -----------------------------------------------------
	const yearLabels = [
		{ display: '2019', date: new Date(2019, 0, 15), anchor: 'start' },
		{ display: '2020', date: new Date(2020, 0, 15), anchor: 'middle' },
		{ display: '2021', date: new Date(2021, 0, 15), anchor: 'middle' },
		{ display: '2022', date: new Date(2021, 11, 15), anchor: 'end' },
	];
	const tlStart = new Date(2019, 0, 15);
	const tlEnd = new Date(2021, 11, 15);
	$: axesY = $height;

	// --- set up animation for highlighted keywords ---------------------------
	let hlKeywords = shuffle([
		{ keyword: 'Bleach', color: trendColors.spike },
		{ keyword: 'Toilet Paper', color: trendColors.spike },
		{ keyword: 'Beans', color: trendColors.spike },
		{ keyword: 'Roller Skates', color: trendColors.sustained },
		{ keyword: 'Tequila', color: trendColors.sustained },
		{ keyword: 'Cat Treats', color: trendColors.sustained },
		{ keyword: 'Coffee', color: trendColors.noChange },
		{ keyword: 'Cookies', color: trendColors.noChange },
		{ keyword: 'Socks', color: trendColors.noChange },
	]);
	let hlIdx = 0;
	$: hlColor = hlKeywords[hlIdx].color;
	$: hlKeyword = hlKeywords[hlIdx].keyword;
	$: currentTrend = getTrendLineData(getTrendByKeyword($trends, hlKeyword));

	let tweenProgress = 0; // <-- tween between 0-1
	let tweenAlpha = 0;
	let tweenStarted = false;
	$: if (currentTrend && !tweenStarted) {
		tweenStarted = true;
		let vals = { progress: 0, alpha: 0 };
		let tl = gsap.timeline({
			onComplete: function () {
				// <-- loop indefinitely
				updateHlIdx();
				tweenAlpha = 0;
				this.restart();
			},
		});
		tl.to(vals, {
			alpha: 1,
			duration: 2,
			onUpdate: () => {
				tweenAlpha = vals.alpha;
			},
		});
		tl.to(
			vals,
			{
				progress: 1,
				duration: 15,
				ease: 'sine.inOut',
				onUpdate: () => {
					tweenProgress = vals.progress;
					setPathLength(); // <- inefficient, but fixes issue where pathlength does not update after first keyword
				},
			},
			'<' // <-- start this animation concurrently with previous step
		);
		tl.to(vals, {
			alpha: 0,
			duration: 1,
			onUpdate: () => {
				tweenAlpha = vals.alpha;
			},
		});
	}

	const updateHlIdx = () => {
		// update the index of the current highlighted keyword
		hlIdx++;
		if (hlIdx >= hlKeywords.length) {
			hlIdx = 0;
		}
	};

	const setPathLength = () => {
		if (pathNode) {
			let newPathLength = pathNode.getTotalLength();
			// ...because setPathLength is being called repeatedly
			if (newPathLength !== pathLength) {
				pathLength = newPathLength;
			}
		}
	};

	let pathNode;
	let pathPoint = { x: 0, y: 0 };
	let pathLength = 0;

	// $: hlKeyword, setPathLength(); // <-- update path length whenever hlKeyword changes
	$: if (pathNode) {
		// convert tween progress to x,y along current path. This allows for path to draw at constant speed;
		if (pathLength == 0) {
			setPathLength();
		}
		pathPoint = pathNode.getPointAtLength(tweenProgress * pathLength); // rightmost point of the current path
	}

	// --- set up BG lines --------------------------------------------------
	const nBG = 10;
	$: bgTrends = shuffle($trends).slice(0, nBG);
</script>

<defs>
	<clipPath id="trend-reveal">
		<!-- animate clipping path from left to right -->
		<rect x={0} y={0} width={pathPoint.x} height={$height} fill="#F00" />
	</clipPath>
	<linearGradient id="gradient" x1="0%" y1="100%" x2="0%" y2="0%">
		<stop offset="10%" stop-color={hlColor} stop-opacity="0" />
		<stop offset="100%" stop-color={hlColor} stop-opacity=".8" />
	</linearGradient>
</defs>

<g class="chart">
	<!-- BG LINES -->
	{#if !isMobile}
		<g class="bg-lines">
			{#each bgTrends as bgTrend}
				<path
					d={pathFn(getTrendLineData(bgTrend))}
					stroke="#000"
					stroke-width={1}
					fill="none"
					opacity={0.1}
				/>
			{/each}
		</g>
	{/if}

	<!-- AXES -->
	<g class="year-axes">
		<line
			x1={$xScale(tlStart)}
			y1={axesY}
			x2={$xScale(tlEnd)}
			y2={axesY}
			stroke="#000"
			stroke-width={1}
		/>
		{#each yearLabels as yearLabel, i}
			<line
				x1={$xScale(yearLabel.date)}
				x2={$xScale(yearLabel.date)}
				y1={axesY}
				y2={axesY + 5}
				stroke="#000"
				stroke-width={1}
			/>
			<text
				class="axis-label"
				text-anchor={yearLabel.anchor}
				dominant-baseline="hanging"
				x={$xScale(yearLabel.date)}
				y={$height + 8}
				fill="#000">{`${yearLabel.display}`}</text
			>
		{/each}
	</g>

	<!-- Highlighted Keyword -->
	<g class="highlighed-line">
		{#if currentTrend}
			<!-- <path
				bind:this={pathNode}
				class="trend-fill"
				d={areaFn(currentTrend)}
				fill="url(#gradient)"
				opacity={tweenAlpha}
				clip-path="url(#trend-reveal)"
			/> -->
			<path
				bind:this={pathNode}
				class="trend-line"
				d={pathFn(currentTrend)}
				stroke={hlColor}
				stroke-width={strokeWidth}
				stroke-linecap="round"
				stroke-dasharray={pathLength}
				stroke-dashoffset={pathLength - tweenProgress * pathLength}
				fill="none"
				opacity={tweenAlpha}
			/>
			<Keyword
				keyword={hlKeyword}
				x={pathPoint.x}
				y={pathPoint.y}
				color={hlColor}
				opacity={tweenAlpha}
			/>
		{/if}
	</g>

	<!-- W.H.O. COVID ANNOTATION -->
	<g class="annotation">
		<line
			x1={$xScale(covidStartDate)}
			y1={axesY}
			x2={$xScale(covidStartDate)}
			y2={$yScale(15)}
			stroke="#000"
			stroke-width={2}
		/>
		<circle cx={$xScale(covidStartDate)} cy={axesY} r={5} fill="#000" />
	</g>
</g>

<style>
	.year-label {
		font-weight: 600;
	}
</style>
