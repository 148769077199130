import './css/fonts.css';
import './css/fullpage.css';
import './css/main.scss';
import './css/charts.css';
import './css/variables.css';

import App from './App.svelte';

var app = new App({
	target: document.getElementById('app'),
	hydrate: false,
});

export default app;
