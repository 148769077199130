export const headerStates = {
	mobile: [
		{ showTitle: false, showInfo: true },
		{ showTitle: false, showInfo: true },
		{ showTitle: false, showInfo: true },
		{ showTitle: false, showInfo: true },
		{ showTitle: false, showInfo: true },
		{ showTitle: false, showInfo: true },
		{ showTitle: false, showInfo: true },
	],
	desktop: [
		{ showTitle: false, showInfo: true },
		{ showTitle: true, showInfo: true },
		{ showTitle: true, showInfo: true },
		{ showTitle: false, showInfo: true },
		{ showTitle: true, showInfo: true },
	],
};
