<script>
	import * as d3 from 'd3';

	import { onMount } from 'svelte';

	import {
		convertDataToTimeSeries,
		getTrendByKeyword,
	} from '../../js/data.js';
	import { shapeColorsHex } from '../../js/colors.js';
	import { trends } from '../../stores/stores.js';

	export let keyword = 'Oranges';
	$: trend = getTrendByKeyword($trends, keyword);
	$: data = trend ? convertDataToTimeSeries(trend.timeline.values) : [];

	const start = new Date(2019, 0, 1);
	const end = new Date(2022, 0, 1);

	export let width = 960;
	export let height = 540;

	const margin = { top: 12, right: 12, bottom: 12, left: 12 };

	$: x = d3
		.scaleTime()
		.domain([start, end])
		.range([margin.left, width - margin.right])
		.nice();

	$: y = d3
		.scaleLinear()
		.domain([0, 100])
		.range([height - margin.bottom, margin.top]);

	$: path = d3
		.line()
		.x((d) => x(d.date))
		.y((d) => y(d.index))
		.curve(d3.curveCardinal);

	const getPath = (trend) => {
		if (!trend) return;

		let data = trend.timeline.values;

		if (!data) return;

		data = data.map((d, i) => {
			const year = 2019 + Math.floor(i / 12);
			const month = i % 12;

			return {
				date: new Date(year, month, 1),
				index: d,
			};
		});

		return path(data);
	};

	// const ticks = x.ticks();
	// const formatMonth = d3.timeFormat('%b');
	// const formatYear = d3.timeFormat('%Y');

	$: trend = getTrendByKeyword($trends, keyword);
	$: color = shapeColorsHex[trend.curatedShape] || '#000';

	onMount(() => {});
</script>

<div class="custom__chart">
	<svg width="100%" height="100%">
		<!-- <rect class='sparkline__background' x="0" y="0" height="270" width="480" /> -->

		{#if trend}
			<path
				class="sparkline"
				d={path(data)}
				stroke-linecap="round"
				stroke={color || '#000'}
			/>
		{/if}
	</svg>
</div>

<style>
	.custom__chart {
		width: 100%;
		height: 100%;

		pointer-events: none;
	}

	.sparkline {
		transition: stroke 0.5s;

		fill: none;
		stroke-width: 2;
		stroke-linecap: round;
	}
</style>
