<script>
	import * as d3 from 'd3';

	import { onMount } from 'svelte';

	import { convertDataToTimeSeries } from '../../../js/data.js';

	export let color;
	export let trend;
	export let width = 960;
	export let height = 540;

	let windowWidth = window.innerWidth;
	$: mobile = windowWidth < 600;

	let chart;

	const series = [
		{
			label: trend.keyword,
			data: convertDataToTimeSeries(trend.timeline.values),
		},
	];

	const margin = { top: 30, right: 60, bottom: 60, left: 30 };

	const start = new Date(2019, 0, 1);
	const end = new Date(2022, 0, 1);

	$: x = d3
		.scaleTime()
		.domain([start, end])
		.range([margin.left, width - margin.right])
		.nice();

	$: xTicks = x ? x.ticks(mobile ? 4 : 12) : [];

	/*		

	const getPath = (trend) => {
		if (!trend) return '';
		const data = trend.timeline.values;

		return path(convertDataToTimeSeries(data));
	};

	*/

	// Y

	$: y = d3
		.scaleLinear()
		.domain([0, 100])
		.range([height - margin.bottom, margin.top]);

	$: area = d3
		.area()
		.x((d) => x(d.date))
		.y0(y(0))
		.y1((d) => y(d.index))
		.curve(d3.curveCardinal);

	$: path = d3
		.line()
		.x((d) => x(d.date))
		.y((d) => y(d.index))
		.curve(d3.curveCardinal);

	const yTicks = [
		{ label: 'Low', y: 0 },
		{ label: 'Medium', y: 50 },
		{ label: 'High Search Interest', y: 100 },
	];

	// Formatters

	const formatMonth = d3.timeFormat('%b');
	const formatYear = d3.timeFormat('%Y');

	// Interaction

	const hover = (event) => {
		return;

		if (!chart) return;

		const bcr = chart.getBoundingClientRect();
		const scale = bcr.width / width;

		line = {
			x: (event.clientX - bcr.left) / scale,
			y: (event.clientY - bcr.top) / scale,
		};
	};

	const getValue = (trend, date) => {
		if (!trend) return '';
		const data = trend.timeline.values;
		const series = convertDataToTimeSeries(data);
		const next = series.findIndex((d) => d.date > date);

		if (next === -1 || !series[next - 1]) return '';

		return series[next - 1].index;
	};

	onMount(() => {});
</script>

<svelte:window bind:innerWidth={windowWidth} />

<div class="custom__chart" bind:offsetWidth={width} bind:offsetHeight={height}>
	<!-- <div class="chart__label">{keyword}</div> -->

	<svg bind:this={chart} width="100%" height="100%">
		<g class="axes-ticks">
			<g class="axis-y">
				{#each yTicks as tick}
					<g transform="translate(0 {y(tick.y)})">
						<line
							class="axis__tick"
							y1="0"
							x1={margin.left}
							y2="0"
							x2={width - margin.right}
						/>
					</g>
				{/each}
			</g>

			<g class="axis-x">
				{#each xTicks as tick}
					<g
						transform="translate({x(tick)} {height -
							margin.bottom})"
					>
						<line class="axis__tick" x1="0" y1="-4" x2="0" y2="4" />
					</g>
				{/each}
			</g>
		</g>

		{#if series}
			{#each series as year}
				<g class="series--highlighted">
					<path
						class="series-path series-{year.label}"
						d={path(year.data)}
						stroke={color || '#ccc'}
					/>
				</g>
			{/each}
		{/if}

		<g class="axis-text">
			<g class="axis-y">
				{#each yTicks as tick}
					<g transform="translate(0 {y(tick.y)})">
						<text class="axis__label" x={margin.left} y="-12"
							>{tick.label}</text
						>
					</g>
				{/each}
			</g>

			<g class="axis-x">
				{#each xTicks as tick}
					<g
						transform="translate({x(tick)} {height -
							margin.bottom})"
					>
						<text
							class="axis__label axis__label--centered"
							x="0"
							y="24">{formatMonth(tick)}</text
						>

						{#if formatMonth(tick) === 'Jan'}
							<text
								class="axis__label axis__label--bold axis__label--centered"
								x="0"
								y="48">{formatYear(tick)}</text
							>
						{/if}
					</g>
				{/each}
			</g>
		</g>
	</svg>
</div>

<style>
	.custom__chart {
		width: 100%;
		height: 100%;
	}
</style>
