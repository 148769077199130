<script>
	// import * as d3 from 'd3';

	import { onMount } from 'svelte';
	import { selectedTrend, shareTrend, showShare, trends } from '../../stores/stores.js';
	import { drawShareCard } from '../../js/shareCardBaseLayer.js';

	let canvas;
	let width = 1440 / 2;
	let height = 900 / 2;

	// export let trend;

	$: {	
		if (canvas && $selectedTrend) {
			drawShareCard(canvas, $selectedTrend);
		}
	}
	
	onMount(() => {
		canvas.getContext('2d').scale(2, 2);
	});
</script>

<canvas
	id="shareCanvas"
	bind:this={canvas}
	width={width * 2}
	height={height * 2}
	style="width:{width}px; height:{height}px"
/>

<style>
	canvas {
		border: 1px solid #dcdcdc;
	}
</style>
