<div class="message">
	<p>Please rotate your phone or widen your screen to view this page.</p>
</div>

<style lang="scss">
	.message {
		position: fixed;
		z-index: 99999;
		top: 0;
		left: 0;

		display: none;
		overflow: hidden;
		align-items: center;
		justify-content: center;

		width: 100%;
		height: 100%;
		padding: 30px;

		text-align: center;

		background: #fff;
	}

	@media only screen and (max-height: 480px) {
		.message {
			display: flex;
		}
	}
</style>
