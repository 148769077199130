import { derived, readable, writable } from 'svelte/store';
import { shuffle } from '../js/data.js';
import { sortAndFilter } from '../js/sortAndFilter.js';

export const trends = writable([]);
export const shareTrend = writable('Toilet Paper');

export const showShare = writable(false);

export const selectedTrend = writable(null);
export const currentSort = writable('sustained');
export const currentFilters = writable([]);

export const sortedAndFiltered = derived(
	[trends, currentFilters, currentSort],
	(values) => sortAndFilter(...values)
);

export const gridInView = writable(false);

getData();

export function getData() {
	fetch('./data/trends_20220106.json')
		.then((response) => response.json())
		.then((data) => {
			const t = data.map((trend) => {
				trend.byYearMonth = processTimelineData(trend.timeline.values);
				trend.byMonth = trend.timeline.values;

				return trend;
			});
			trends.set(t);
		});
}

function processTimelineData(values) {
	let data = [];

	values.forEach((value, index) => {
		let month = index % 12;
		let year = 2019 + Math.floor(index / 12);

		if (!data[month]) data[month] = {};

		data[month][year] = value;
	});

	return data;
}

function getTopKeywordsByProfile(kws, profile) {
	// return the top keywords from the given profile category (i.e. spike, noChange, sustained)
	const nKeywordsPerCat = 35;
	return kws
		.filter((d) => d.profileCategory === profile)
		.sort((a, b) => b['model'][profile] - a['model'][profile])
		.slice(0, nKeywordsPerCat);
}
