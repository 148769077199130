<script>
	import * as d3 from 'd3';

	import { LayerCake, Svg } from 'layercake';
	import Chart from './Shapes.chart.svelte';

	import { trends } from '../../stores/stores.js';
	import { getTrendByKeyword, getTrendLineData } from '../../js/data.js';
	import { shapeCardsData } from '../../data/shapeCardsData';

	export let shape;
	export let cardIdx;
	export let isInView = false;

	let shapeData = shapeCardsData.find((d) => d.shapeName === shape);
	let trend;
	$: if ($trends.length > 0) {
		trend = getTrendLineData(getTrendByKeyword($trends, shapeData.keyword));
	}

	let node;
	let height, width;
</script>

<div
	class="shape-mobile-card"
	bind:this={node}
	bind:clientHeight={height}
	clientWidth={width}
>
	<div class="shape-heading">
		<div class="shape-icon-container">
			<img
				class={`shape-icon ${shapeData.shapeName}-icon`}
				src={`./assets/${shapeData.icon}`}
				alt=""
			/>
		</div>
		<h1>{shapeData.display}</h1>
		<div class="description">{@html shapeData.description}</div>
	</div>

	{#if trend}
		<div class="chart-container">
			<LayerCake
				xScale={d3.scaleTime()}
				xDomain={[new Date(2019, 0, 1), new Date(2021, 11, 30)]}
				yDomain={[0, 80]}
				padding={{ top: 15, bottom: 30, left: 15, right: 15 }}
			>
				<Svg>
					<Chart
						{isInView}
						chartIdx={cardIdx}
						keyword={shapeData.keyword}
						color={shapeData.color}
						{trend}
					/>
				</Svg>
			</LayerCake>
		</div>
	{/if}
</div>

<style lang="scss">
	.shape-mobile-card {
		width: 100%;
		// height: 100vh;
		display: flex;
		flex-direction: column;
		padding: 10vh 0;
		background-color: white;
		z-index: -1;

		.shape-heading {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;

			h1 {
				font-weight: 800;
				font-size: 36px;
				line-height: 125%;
				letter-spacing: -2.2%;
				margin: 0 10px;
				text-transform: uppercase;
			}

			.shape-icon-container {
				z-index: -1;
				width: 50px;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.unusual-icon {
				transform: translate(
					0,
					-3px
				); // <- needs to be adjusted up to look centered
			}

			img {
				width: 45px;
			}
		}

		.chart-container {
			width: 100%;
			height: 70vh;
			align-self: flex-end;
			margin: 50px 0;
		}
	}

	p {
		font-size: 18px;
		line-height: 22.5px;
		letter-spacing: 1.25;
		text-align: center;
	}

	.description {
		font-size: 18px;
		line-height: 22.5px;
		letter-spacing: 1.25;
		text-align: center;
		padding-top: 15px;

		:global(span) {
			font-weight: 600;
		}

		:global(.normal) {
			color: var(--red);
		}

		:global(.unusual) {
			color: var(--yellow);
		}

		:global(.new-normal) {
			color: var(--blue);
		}
	}
</style>
