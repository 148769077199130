<script>
	import { onMount, createEventDispatcher } from 'svelte';
	import { fade } from 'svelte/transition';
	import mq from '../stores/mq.js';
	import { headerStates } from '../data/headerStates';

	import Logos from '../components/Logos.svelte';
	import Controls from '../components/grid/Grid.Controls.svelte';
	export let currentSlideIdx;

	$: isMobile = $mq.sm;
	$: states = isMobile ? headerStates['mobile'] : headerStates['desktop'];
	$: currentState = states[currentSlideIdx];

	const dispatch = createEventDispatcher();
	function emitEvent(msg) {
		dispatch(msg);
	}

	onMount(() => {});
</script>

<header>
	<!-- <div class="header-section left">
		{#if currentState && currentState.showTitle}
			<div class="empty" />
		{/if}
	</div> -->

	<div class="header-section middle">
		{#if currentState && currentState.showTitle}
			<div class="home-button-container">
				<button on:click={() => emitEvent('homeClick')}
					>THE NEW NORMAL</button
				>
			</div>
		{:else}
			<Controls />
		{/if}
	</div>

	<div class="header-section right">
		{#if currentState && currentState.showInfo}
			<button transition:fade on:click={() => emitEvent('aboutClick')}
				><img alt='Info icon' src="./assets/Icon-Info.svg" /></button
			>
		{/if}
	</div>
</header>

<style>
	header {
		position: fixed;
		z-index: 9999;
		top: 0px;

		display: flex;
		justify-content: space-between;

		width: 100%;
		height: 48px;
		padding: 9px 10px 9px 20px;

		letter-spacing: 0;

		color: #000;
		border-bottom: 1px solid #f4f4f4;
		background: #fff;

		font-size: 16px;
		font-weight: 800;
		line-height: 24px;
	}

	.header-section {
		min-width: 128px;
	}

	.left {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.middle {
		width: 100%;
	}

	.right {
		transform: scale(0.9);
		text-align: right;
	}

	.home-button-container {
		position: fixed;
		top: 13px;
		left: 50%;

		transform: translate(-50%, 0);
	}

	button {
		margin: 0;
		padding: 0;

		transition: opacity 0.5s;
		white-space: nowrap;

		border: 0;
		background: transparent;

		font-size: inherit;
		font-weight: inherit;
	}

	@media (hover: hover) {
		button:hover {
			opacity: 0.5;
		}
	}

	@media screen and (max-width: 600px) {
		.right {
			/* padding-right: 15px; */
		}
	}
</style>
