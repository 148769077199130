<script>
	import { onMount, tick } from 'svelte';

	import {
		currentFilters,
		currentSort,
		gridInView,
		selectedTrend,
		sortedAndFiltered,
		trends,
	} from '../../stores/stores.js';

	import Trends from './Grid.Trends.svelte';

	export let isInView = false;

	let windowWidth = window.innerWidth;
	let windowHeight = window.innerHeight;

	$: mobile = windowWidth < 600;

	$: height = getHeight($sortedAndFiltered, mobile);

	const getHeight = (trends, mobile) => {
		if (mobile) return `auto`;

		const h = windowHeight;
		const increment = (h - 48) / 10;

		let rows = Math.ceil(trends.length / 10);

		// rows = rows <= 10 ? 10 : rows + 1;

		return `${increment * rows + 48}px`;
	};

	// Keep track of grid in view
	$: gridInView.set(isInView);

	// If height changes, rebuild fullpage
	$: if (height && window.fullpage_api) rebuild();

	// On mobile, when sorts changes put in view
	/*
	$: if (mobile && $sortedAndFiltered) {
		setTimeout(() => {
			scrollGridIntoView();
		}, 0);
	}
	*/

	function rebuild() {
		setTimeout(() => {
			if (window.fullpage_api) window.fullpage_api.reBuild();
		}, 1000);
	}

	onMount(() => {});
</script>

<svelte:window bind:innerHeight={windowHeight} bind:innerWidth={windowWidth} />

<div
	id="grid"
	class="grid grid--{$currentSort} grid--{selectedTrend
		? 'selected'
		: 'unselected'}"
	style="height:{height}"
>
	<Trends {isInView} />
</div>

<style>
	.grid {
		position: relative;

		overflow: hidden;

		min-height: 100vh;
		margin: auto;
	}

	/* Mobile */

	@media screen and (max-width: 600px) {
		.grid {
			overflow: auto;

			height: auto;
			/* min-height: 0; */
		}
	}
</style>
