<script>
	import * as d3 from 'd3';

	import { onMount } from 'svelte';

	import { convertDataToTimeSeries } from '../../../js/data.js';

	export let color;
	export let trend;
	export let width = 960;
	export let height = 540;

	let windowWidth = window.innerWidth;
	$: mobile = windowWidth < 600;

	let chart;

	let selectedSeries = '2020';

	const series = [
		{
			label: '2019',
			data: trend.timeline.values.slice(0, 12).map(toDataPair),
		},
		{
			label: '2020',
			data: trend.timeline.values.slice(12, 24).map(toDataPair),
		},
		{
			label: '2021',
			data: trend.timeline.values.slice(24).map(toDataPair),
		},
	];

	/*

	$: sortedSeries = series.sort((a, b) => {
		return a.label === selectedSeries ? 1 : -1;
	});

	*/

	const margin = { top: 30, right: 60, bottom: 60, left: 30 };

	$: x = d3
		.scaleLinear()
		.domain([0, 11])
		.range([margin.left, (width || 0) - margin.right])
		.nice();

	$: xTicks = x ? x.ticks(mobile ? 5 : 12) : [];

	$: path = d3
		.line()
		.x((d) => x(d.date))
		.y((d) => y(d.index))
		.curve(d3.curveCardinal);

	// Y

	$: y = d3
		.scaleLinear()
		.domain([0, 100])
		.range([(height || 0) - margin.bottom, margin.top]);

	const yTicks = [
		{ label: 'Low', y: 0 },
		{ label: 'Medium', y: 50 },
		{ label: 'High Search Interest', y: 100 },
	];

	// Formatters

	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	function toDataPair(index, i) {
		return { date: i, index: index };
	}

	const formatMonth = (index) => {
		return months[index];
	};

	onMount(() => {});
</script>

<!-- <svelte:window bind:innerHeight={height} /> -->

<div class="custom__chart" bind:offsetWidth={width} bind:offsetHeight={height}>
	<svg bind:this={chart} width="100%" height="100%">
		<g class="axes-ticks">
			<g class="axis-y">
				{#each yTicks as tick}
					<g transform="translate(0 {y(tick.y)})">
						<line
							class="axis__tick"
							y1="0"
							x1={margin.left}
							y2="0"
							x2={width - margin.right}
						/>
					</g>
				{/each}
			</g>

			<g class="axis-x">
				{#each xTicks as tick}
					<g
						transform="translate({x(tick)} {height -
							margin.bottom})"
					>
						<line class="axis__tick" x1="0" y1="-4" x2="0" y2="4" />
					</g>
				{/each}
			</g>
		</g>

		{#if series}
			<g class="series--tinted">
				{#each series as series (series.label)}
					<g class="series--{series.label}">
						<path
							on:click={() => (selectedSeries = series.label)}
							on:mouseenter={() =>
								(selectedSeries = series.label)}
							class="series-path"
							d={path(series.data)}
						/>
					</g>
				{/each}
			</g>

			<g class="series--highlighted">
				{#each series as series (series.label)}
					<g
						class="series--{selectedSeries === series.label
							? 'selected'
							: 'deselected'}"
					>
						<path
							on:mouseenter={() =>
								(selectedSeries = series.label)}
							class="series-path"
							d={path(series.data)}
						/>

						<g
							transform="translate({x(
								series.data[series.data.length - 1].date
							)} {y(series.data[series.data.length - 1].index)})"
						>
							<rect
								class="series-label-background"
								x="-15"
								y="-18"
								width="60"
								height="36"
								rx="6"
							/>

							<text class="series-label-text" x="16" y="0"
								>{series.label}</text
							>
						</g>
					</g>
				{/each}
			</g>
		{/if}

		<g class="axes-text">
			<g class="axis-y">
				{#each yTicks as tick}
					<g transform="translate(0 {y(tick.y)})">
						<text class="axis__label" x={margin.left} y="-12"
							>{tick.label}</text
						>
					</g>
				{/each}
			</g>

			<g class="axis-x">
				{#each xTicks as tick}
					<g
						transform="translate({x(tick)} {height -
							margin.bottom})"
					>
						<text
							class="axis__label axis__label--centered"
							x="0"
							y="24">{formatMonth(tick)}</text
						>
					</g>
				{/each}
			</g>
		</g>
	</svg>
</div>

<style>
	.custom__chart {
		width: 100%;
		height: 100%;
	}
</style>
