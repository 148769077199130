<script>
	import mq from '../../stores/mq.js';

	export let keyword = '';
	export let x = 0;
	export let y = 0;
	export let color = '#000';
	export let opacity = 1;

	$: isMobile = $mq.sm;

	// --- Set dimensions of text box
	let textNode;
	let textBox = { width: 0, height: 0 };
	$: {
		if (textNode) {
			textNode.textContent = keyword; // <-- set text content here so we can get width
			textNode = textNode;
			textBox = textNode.getBBox();
		}
	}

	// --- Set dimensions of label box containing text box
	let padding;
	$: if (isMobile) {
		padding = { left: 7, right: 7, top: 7, bottom: 7 };
	} else {
		padding = { left: 10, right: 10, top: 10, bottom: 10 };
	}
	$: labelBox = {
		width: textBox.width + padding.left + padding.right,
		height: textBox.height + padding.top + padding.bottom,
	};

	// --- Set the position of the of top left corner of labelbox
	$: pos = {
		x: x - labelBox.width - 10,
		y: y - labelBox.height - 10,
	};
</script>

<g transform={`translate(${pos.x}, ${pos.y})`}>
	<rect
		class="label-bg"
		x="0"
		y="0"
		width={labelBox.width}
		height={labelBox.height}
		rx={5}
		fill={color}
		stroke="#FFF"
		stroke-width={2}
		{opacity}
	/>
	<text
		bind:this={textNode}
		class="highlighted-keyword"
		text-anchor="middle"
		dominant-baseline="middle"
		x={labelBox.width / 2}
		y={labelBox.height / 2 + 2}
		{opacity}
		fill="#FFF"
	/>
</g>

<style>
	.highlighted-keyword {
		font-size: 16px;
		line-height: 22px;
		letter-spacing: 0.1em;
		font-weight: 800;
		text-transform: uppercase;
	}

	@media screen and (max-width: 600px) {
		.highlighted-keyword {
			font-size: 14px;
			line-height: 18px;
		}
	}
</style>
