<script>
	import { onMount, tick } from 'svelte';
	import { showShare } from './stores/stores.js';
	import mq from './stores/mq.js';

	import 'fullpage.js/vendors/scrolloverflow';
	import fullpage from 'fullpage.js';

	import Header from './components/Header.svelte';
	import Background from './components/Background.svelte';
	import NavArrow from './components/NavArrow.svelte';
	import Cover from './components/cover/Cover.svelte';
	import Intro from './components/intro/Intro.svelte';
	import Grid from './components/grid/Grid.svelte';
	import Shapes from './components/shapes/Shapes.svelte';
	import ShapeMobile from './components/shapes/Shapes.mobileCard.svelte';

	import Selected from './components/grid/Grid.Selected.svelte';

	import Modal from './components/Modal.svelte';
	import About from './components/about/About.svelte';
	import RotateOverlay from './components/messages/RotateOverlay.svelte';
	import Share from './components/share/Share.svelte';

	// --- Components and FP interaction ---------------------
	$: isMobile = $mq.sm;
	$: components = isMobile
		? [
				{ cmp: Cover, props: {} },
				{ cmp: Intro, props: {} },
				{ cmp: ShapeMobile, props: { shape: 'newNormal', cardIdx: 0 } },
				{ cmp: ShapeMobile, props: { shape: 'unusual', cardIdx: 1 } },
				{ cmp: ShapeMobile, props: { shape: 'normal', cardIdx: 2 } },
				{ cmp: Grid, props: {} },
				{ cmp: About, props: {} },
		  ]
		: [
				{ cmp: Cover, props: {} },
				{ cmp: Intro, props: {} },
				{ cmp: Shapes, props: {} },
				{ cmp: Grid, props: {} },
				{ cmp: About, props: {} },
		  ];

	let currentCmpIdx = 0;

	$: components, rebuildFP();

	const rebuildFP = async () => {
		if (fpAPI) {
			fpAPI.destroy('all');
			await tick();
			initFP();
		}
	};

	const iPad = navigator.maxTouchPoints > 0;

	const initFP = () => {
		new fullpage('#fullpage-container', {
			licenseKey: 'F98AA2EC-ECE948C5-8EE47663-BC138169',

			controlArrows: true,
			css3: true,
			dragAndMove: false,
			fixedElements: ['#nav'],
			interlockedSlides: false,
			navigation: true,
			normalScrollElements: '.ignore-scroll',
			responsiveWidth: !iPad ? 768 : 2400,
			scrollOverflow: true,
			scrollingSpeed: 1300,

			onLeave: (origin, destination, direction) => {
				currentCmpIdx = destination.index;
			},
		});

		fpAPI = window.fullpage_api;
	};

	let fpAPI;

	onMount(async () => {
		initFP();
	});

	// --- Event Handlers --------------------
	const goToAbout = () => {
		if (fpAPI) {
			fpAPI.moveTo(components.length); // <-- About is always last component
		}
	};
	const goToStart = () => {
		if (fpAPI) {
			fpAPI.moveTo(1); // <-- 1-based indexing
		}
	};
	const goToNext = () => {
		if (fpAPI) {
			fpAPI.moveSectionDown(); // <-- 1-based indexing
		}
	};
</script>

<RotateOverlay />

<Header
	on:aboutClick={goToAbout}
	on:homeClick={goToStart}
	currentSlideIdx={currentCmpIdx}
/>
<Background />
<NavArrow
	on:nextClick={goToNext}
	on:homeClick={goToStart}
	currentSlideIdx={currentCmpIdx}
/>
<Share />
<Selected />

<!-- Full Page wrapper -->
<div id="fullpage-container">
	<section id="nav">
		<button>prev</button>
		<button>next</button>
	</section>
	{#each components as cmp, i}
		<div class="section">
			<svelte:component
				this={cmp.cmp}
				isInView={currentCmpIdx === i}
				{...cmp.props}
			/>
		</div>
	{/each}
</div>

<style>
	section {
		width: 100%;
		height: 100vh;
	}

	#nav {
		display: none;
	}
</style>
