import { trendColors } from '../js/colors';

// --- define content for shapes --------------
export const shapeCardsData = [
	{
		display: 'New Normal',
		shapeName: 'newNormal',
		icon: 'Shape-Sustained.svg',
		keyword: 'Hair Care',
		color: trendColors.sustained,
		description:
			'Search interest increased <span class=new-normal>during the pandemic and remains high</span>',
	},
	{
		display: 'Unusual',
		shapeName: 'unusual',
		icon: 'Shape-Spike.svg',
		keyword: 'Bleach',
		color: trendColors.spike,
		description:
			'Search interest <span class=unusual>increased during the pandemic, but has returned to normal</span>',
	},
	{
		display: 'Normal',
		shapeName: 'normal',
		icon: 'Shape-Normal.svg',
		keyword: 'Eggs',
		color: trendColors.noChange,
		description:
			'Search interest remains <span class="normal">relatively unaffected</span> by the pandemic',
	},
];
