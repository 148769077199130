const URL = window.URL || window.webkitURL || window;

export function downloadSVGasPNG(svg, name) {
	const serialized = new XMLSerializer().serializeToString(svg);

	const blob = new Blob([serialized], { type: 'image/svg+xml' });
	const blobURL = URL.createObjectURL(blob);

	const canvas = document.createElement('canvas');
	canvas.width = 960;
	canvas.height = 540;

	const context = canvas.getContext('2d');

	const image = new Image();

	image.onload = () => {
		context.drawImage(image, 0, 0);

		download(canvas.toDataURL('image/png'), name);
	};

	image.src = blobURL;
}

export function downloadCanvasAsPNG(canvas, name) {
	download(canvas.toDataURL('image/png'), name);
}

function download(href, name) {
	const a = document.createElement('a');

	a.download = name;
	a.href = href;

	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}
