export const colors = {
	red: '#F87564',
	yellow: '#F4CF27',
	blue: '#61BEDF',
};

export const shapeColorsName = {
	Normal: "red",
	Unusual: "yellow",
	'New Normal': "blue",
};

export const shapeColorsHex = {
	Normal: colors.red,
	Unusual: colors.yellow,
	'New Normal': colors.blue,
};

export const trendColors = {
	noChange: colors.red,
	spike: colors.yellow,
	sustained: colors.blue,
};