<script>
	import { getContext } from 'svelte';

	const { xScale, yScale } = getContext('LayerCake');
	const covidStartDate = new Date(2020, 2, 11); // <- March 11 2020, WHO declares a pandemic

	$: style = $xScale
		? `top: ${$yScale(15.5)}px; left: ${$xScale(covidStartDate) + 6}px`
		: '';
</script>

<div class="annotation-container" {style}>
	<div class="date">March 11, 2020</div>
	<div class="text">
		The World Health Organization declares Covid-19 outbreak a global
		pandemic
	</div>
</div>

<style>
	.annotation-container {
		position: absolute;
		width: 210px;
	}

	.date {
		font-size: 14px;
		line-height: 1.35;
		font-weight: 300;
	}

	.text {
		font-size: 14px;
		line-height: 1.35;
		font-weight: 600;
	}
</style>
